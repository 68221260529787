/* eslint-disable no-unused-vars */
import {
  Box,
  Flex,
  Radio,
  RadioGroup,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import {
  CANCEL_CLASS,
  CLASS_DETAILS,
  MARK_ATTENDANCE,
  TRAINER_ADD_NOTE,
  TRAINER_DELETE_NOTE,
} from 'version-two/api/urls';
import ProgramTitleCard from 'version-two/components/ProgramTitleCard/ProgramTitleCard';
import AppButton from 'version-two/components/Typography/AppButton';
import appColors from 'version-two/theme/appColors';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import { useMutation, useQuery } from 'react-query';
import {
  extractTime,
  formatDate,
  toastFunction,
} from 'version-two/components/CommonFunctions/CommonFunctions';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import makeDeleteRequest from 'version-two/api/utils/makeDeleteRequest';
import { TbDotsVertical } from 'react-icons/tb';
import TeacherAddNoteDialog from '../TeacherAddNoteDialog/TeacherAddNoteDialog';

function TeacherClassDetail({ classDetails, programDetail }) {
  const toast = useToast();
  const [attendance, setAttendance] = useState({});
  const {
    isOpen: noteIsOpen,
    onOpen: noteOnOpen,
    onClose: noteOnClose,
  } = useDisclosure();
  const noteRef = useRef();
  const [openSettingsIndex, setOpenSettingsIndex] = useState(null);
  const [notesId, setNotesId] = useState(null);

  const {
    data: classData,
    isLoading,
    refetch,
  } = useQuery(
    CLASS_DETAILS(classDetails?.id),
    () => makeGetRequest(CLASS_DETAILS(classDetails?.id)),
    {
      onSuccess: (data) => {
        const initialAttendance = data?.data?.attendance.reduce(
          (acc, student) => {
            acc[student.id] = student.status || '';
            return acc;
          },
          {},
        );
        setAttendance(initialAttendance);
      },
    },
  );

  const { mutate: attendanceMutate } = useMutation(
    (body) => makePostRequest(MARK_ATTENDANCE, body),
    {
      onSuccess: () => {
        toastFunction(toast, 'Attendance Marked', 'success');
        refetch();
      },
      onError: () => {
        toastFunction(toast, 'Attendance Mot Marked', 'error');
      },
    },
  );

  const { mutate: cancelClassMutate } = useMutation(
    (body) => makePostRequest(CANCEL_CLASS(classDetails?.id), body),
    {
      onSuccess: () => {
        toastFunction(toast, 'Cancel Class Successfully', 'success');
        // window.location.reload();
      },
      onError: (error) => {
        if (error?.response?.data?.non_field_errors) {
          error?.response?.data?.non_field_errors?.map((err) =>
            toastFunction(toast, err, 'error'),
          );
        } else {
          toastFunction(toast, 'Class Cancellation Failed', 'error');
        }
      },
    },
  );

  const { mutate: noteMutate } = useMutation(
    (body) => makePostRequest(TRAINER_ADD_NOTE, body),
    {
      onSuccess: () => {
        refetch();
        toastFunction(toast, 'Note Added Successfully', 'success');
      },
      onError: (error) => {
        if (error?.response?.data?.non_field_errors) {
          error?.response?.data?.non_field_errors?.map((err) =>
            toastFunction(toast, err, 'error'),
          );
        } else {
          toastFunction(toast, 'Note Creation Failed', 'error');
        }
      },
    },
  );

  const { mutate: deletenoteMutate } = useMutation(
    (body) => makeDeleteRequest(TRAINER_DELETE_NOTE(notesId)),
    {
      onSuccess: () => {
        toastFunction(toast, 'Note Deleted Successfully', 'success');
        refetch();
      },
      onError: () => {
        toastFunction(toast, 'Something went wrong. Try again', 'error');
      },
    },
  );

  const addNote = (note) => {
    noteMutate({
      class_record: classDetails?.id,
      message: note,
    });
    noteOnClose();
  };

  const handleSettingsClick = (index, id) => {
    setOpenSettingsIndex(index === openSettingsIndex ? null : index);
    setNotesId(id);
  };

  const deleteHandler = () => {
    deletenoteMutate({});
  };

  const CancelClassHandler = () => {
    cancelClassMutate({
      name: classData?.data?.name,
      date: classData?.data?.date,
      trainer_name: classData?.data?.trainer_name,
    });
  };

  const handleAttendanceChange = (id, status) => {
    setAttendance((prev) => ({
      ...prev,
      [id]: status,
    }));
  };

  const handleSetAllAttendance = (status) => {
    const newAttendance = classData?.data?.attendance.reduce((acc, student) => {
      acc[student.id] = status;
      return acc;
    }, {});
    setAttendance(newAttendance);
  };

  const formattedAttendance = Object.entries(attendance).map(
    ([id, status]) => ({
      id,
      status,
    }),
  );

  const handleSaveAttendance = () => {
    attendanceMutate({
      attendance: formattedAttendance,
    });
  };

  const getPresentCount = () =>
    Object.values(attendance).filter((status) => status === 'Present').length;

  const isAttendanceBtnDisabled = () => {
    const present = Object.values(attendance).filter(
      (status) => status === 'Present',
    ).length;
    const absent = Object.values(attendance).filter(
      (status) => status === 'Absent',
    ).length;

    if (present > 0 || absent > 0) {
      return false;
    }
    return true;
  };

  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <>
      <ProgramTitleCard
        title={classDetails?.name}
        type={programDetail?.data.batch.identity}
      />
      <AppLoader isLoading={isLoading} height="30vh">
        <Flex
          flexDirection="column"
          gap="30px"
          width="100%"
          bg={appColors.brandWhite[900]}
          mb="50px"
        >
          <Flex flexDirection="column" gap={5}>
            <Text
              fontWeight="700"
              color={appColors.brandSecondary[500]}
              fontSize={isMobile ? '16px' : '24px'}
            >
              Student List
            </Text>
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              gap={3}
              fontSize={isMobile ? '13px' : '16px'}
            >
              <Text>Set attendance for all students as</Text>
              <Flex gap={5} mr={isMobile ? '10px' : '74px'}>
                <RadioGroup
                  display="flex"
                  gap={5}
                  onChange={handleSetAllAttendance}
                >
                  <Radio
                    value="Present"
                    display="flex"
                    flexDirection={isMobile ? 'column' : 'row'}
                    alignItems="center"
                  >
                    <Text mt={isMobile ? 2 : 0}>Present</Text>
                  </Radio>
                  <Box
                    display={isMobile ? 'block' : 'none'}
                    height="auto"
                    borderRight={`1px solid ${appColors.brandGrey[200]}`}
                  />
                  <Radio
                    value="Absent"
                    display="flex"
                    flexDirection={isMobile ? 'column' : 'row'}
                    alignItems="center"
                  >
                    <Text mt={isMobile ? 2 : 0}>Absent</Text>
                  </Radio>
                </RadioGroup>
              </Flex>
            </Flex>
            <Flex
              width="100%"
              flexDirection="column"
              gap={5}
              borderTop={`1px solid ${appColors.brandGrey[200]}`}
              className="class-details"
            >
              <TableContainer>
                <Table
                  colorScheme="facebook"
                  fontSize={isMobile ? '13px' : '16px'}
                >
                  {!isMobile && (
                    <Thead>
                      <Tr>
                        <Th maxWidth="100px" py="20px">
                          <Text
                            color={appColors.brandGrey[900]}
                            fontSize="12px"
                            fontWeight={700}
                            pl="100px"
                          >
                            S.NO
                          </Text>
                        </Th>
                        <Th>
                          <Text
                            color={appColors.brandGrey[900]}
                            fontSize="12px"
                            fontWeight={700}
                          >
                            STUDENT NAME
                          </Text>
                        </Th>
                        <Th width="200px">
                          <Text
                            color={appColors.brandGrey[900]}
                            fontSize="12px"
                            fontWeight={700}
                          >
                            ATTENDANCE
                          </Text>
                        </Th>
                      </Tr>
                    </Thead>
                  )}
                  <Tbody>
                    {classData?.data?.attendance?.map((classItem, index) => (
                      <Tr key={`attendance-${classItem?.id}`}>
                        {!isMobile && (
                          <Td maxWidth="100px">
                            <Text pl="100px">{index + 1}</Text>
                          </Td>
                        )}
                        <Td>
                          <Text>{classItem?.name}</Text>
                        </Td>
                        <Td width={isMobile ? 'auto' : '200px'}>
                          <RadioGroup
                            name={`attendance-${classItem?.id}`}
                            display="flex"
                            justifyContent="flex-end"
                            gap={5}
                            value={attendance[classItem?.id] || ''}
                            onChange={(value) =>
                              handleAttendanceChange(classItem?.id, value)
                            }
                          >
                            <Radio
                              value="Present"
                              display="flex"
                              flexDirection={isMobile ? 'column' : 'row'}
                              alignItems="center"
                            >
                              <Text mt={isMobile ? 2 : 0}>Present</Text>
                            </Radio>
                            <Box
                              display={isMobile ? 'block' : 'none'}
                              height="auto"
                              borderRight={`1px solid ${appColors.brandGrey[200]}`}
                            />
                            <Radio
                              value="Absent"
                              display="flex"
                              flexDirection={isMobile ? 'column' : 'row'}
                              alignItems="center"
                            >
                              <Text
                                mt={isMobile ? 2 : 0}
                                pr={isMobile ? '0px' : '50px'}
                              >
                                Absent
                              </Text>
                            </Radio>
                          </RadioGroup>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>
            <Flex
              width="100%"
              justifyContent="space-between"
              gap={3}
              alignItems={isMobile ? 'flex-start' : 'center'}
              flexDirection={isMobile ? 'column' : 'row'}
              fontSize={isMobile ? '13px' : '16px'}
            >
              <Text>
                Total no. of students present - {getPresentCount()}/
                {classData?.data?.attendance?.length}
              </Text>
              <AppButton
                title="Save Attendance"
                variant="solid"
                customStyles={{ m: '0', width: isMobile ? '100%' : 'auto' }}
                disabled={isAttendanceBtnDisabled()}
                onClick={handleSaveAttendance}
              />
            </Flex>
          </Flex>

          <Flex
            flexDirection="column"
            display={
              classData?.data?.status === 'Upcoming' &&
              classData?.data?.link !== 'Triggered' &&
              !classData?.data?.file
                ? 'flex'
                : 'none'
            }
          >
            <Text
              fontSize={isMobile ? '16px' : '24px'}
              fontWeight="700"
              gap={3}
              color={appColors.brandSecondary[500]}
            >
              Cancel Class
            </Text>
            <Flex
              width="100%"
              justifyContent="space-between"
              gap={3}
              alignItems={isMobile ? 'flex-start' : 'center'}
              flexDirection={isMobile ? 'column' : 'row'}
              fontSize={isMobile ? '13px' : '16px'}
            >
              <Text>
                Are you sure you want to cancel the Class on this day (
                {formatDate(classData?.data?.date)})
              </Text>
              <AppButton
                title="Cancel Class"
                variant="solid"
                customStyles={{ m: '0', width: isMobile ? '100%' : 'auto' }}
                onClick={CancelClassHandler}
              />
            </Flex>
          </Flex>

          <Flex
            width="100%"
            justifyContent="space-between"
            gap={3}
            alignItems={isMobile ? 'flex-start' : 'center'}
            flexDirection={isMobile ? 'column' : 'row'}
            fontSize={isMobile ? '13px' : '16px'}
          >
            <Text
              fontSize={isMobile ? '16px' : '24px'}
              fontWeight="700"
              color={appColors.brandSecondary[500]}
            >
              Teacher&apos;s Note
            </Text>
            <AppButton
              title="Add Note"
              variant="outline"
              customStyles={{ m: '0', width: isMobile ? '100%' : 'auto' }}
              onClick={noteOnOpen}
            />
          </Flex>
          <TeacherAddNoteDialog
            isOpen={noteIsOpen}
            onClose={noteOnClose}
            leastDestructiveRef={noteRef}
            onSubmit={addNote}
          />
          {classData?.data?.notes?.length > 0 && (
            <Flex width="100%" flexDirection="column" gap={5}>
              {classData?.data?.notes?.map((item, index) => (
                <Flex
                  key={item?.id}
                  width="100%"
                  justifyContent="space-between"
                  border={`1px solid ${appColors.brandGrey[200]}`}
                  borderRadius="10px"
                  padding={isMobile ? '10px' : '10px 20px'}
                  fontSize={isMobile ? '13px' : '16px'}
                >
                  <Flex flexDirection="column" gap="10px">
                    <Flex gap="15px" alignItems="center">
                      <Text color={appColors.brandSecondary[500]}>
                        {item?.trainer_name}
                      </Text>
                      <Flex gap="10px" alignItems="center">
                        <Box boxSize="4px" borderRadius="50%" bg="#CFD7DF" />
                        <Text fontSize={isMobile ? '11px' : '16px'}>
                          Created on {formatDate(item?.created)}{' '}
                          {extractTime(item?.created)}
                        </Text>
                      </Flex>
                    </Flex>
                    <Text>{item?.message}</Text>
                  </Flex>
                  <Flex position="relative">
                    <Box
                      boxSize="20px"
                      cursor="pointer"
                      onClick={() => handleSettingsClick(index, item?.id)}
                    >
                      <TbDotsVertical />
                    </Box>
                    {openSettingsIndex === index && (
                      <Flex
                        bg="white"
                        px="20px"
                        alignItems="center"
                        gap="10px"
                        py="9px"
                        position="absolute"
                        top="0px"
                        left="-90px"
                        border={`1px solid ${appColors.brandGrey[200]}`}
                        borderRadius="5px"
                        cursor="pointer"
                        onClick={deleteHandler}
                        zIndex="1"
                        _hover={{ color: appColors.brandSecondary[500] }}
                      >
                        <Text>Delete</Text>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
      </AppLoader>
    </>
  );
}

export default TeacherClassDetail;
