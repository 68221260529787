/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable no-shadow */
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EDIT_TRAINER,
  EDIT_TRAINER_TIMESLOTS,
  TRAINER_META,
  VIEW_TRAINER,
} from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import makePutRequest from 'version-two/api/utils/makePutRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import AppSelectV3 from 'version-two/components/AppSelect/AppSelectV3';
import { toastFunction } from 'version-two/components/CommonFunctions/CommonFunctions';
import ProgramTitleCard from 'version-two/components/ProgramTitleCard/ProgramTitleCard';
import AppButton from 'version-two/components/Typography/AppButton';
import AppInputV3 from 'version-two/components/Typography/AppInputV3';
import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
import {
  TEACHER_DASHBOARD_AVAILABILITY,
  TEACHER_DASHBOARD_MANAGE_AVAILABILITY,
} from 'version-two/navigation/routes';
import useAppStore from 'version-two/store';
import appColors from 'version-two/theme/appColors';

function TeacherManageAvailability() {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const [availability, setAvailability] = useState({});
  const [timeSlotId, setTimeSlotId] = useState(null);
  const [day, setDay] = useState(null);
  const [removeTimeSlot, setRemoveTimeSlot] = useState(false);
  const trainerId = useAppStore.getState().trainer_id;
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const {
    register,
    handleSubmit,
    watch,
    control,
    trigger,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const {
    data: trainerData,
    isLoading: trainerIsLoading,
    isError: trainerIsError,
    error: trainerError,
    refetch,
  } = useQuery(
    [VIEW_TRAINER(trainerId)],
    () => makeGetRequest(VIEW_TRAINER(trainerId)),
    {
      onSuccess: (res) => {
        setAvailability(res?.availability);
      },
    },
  );
  const { data: MetaData, isLoading: MetaIsLoading } = useQuery(
    TRAINER_META,
    () => makeGetRequest(TRAINER_META),
  );

  const {
    data: updatedtrainerData,
    isLoading: updatedTrainerIsLoading,
    isError: updatedTrainerIsError,
    error: updatedTrainerError,
    mutate: trainerMutate,
  } = useMutation((body) => makePutRequest(EDIT_TRAINER(trainerId), body), {
    onSuccess: (res) => {
      toastFunction(toast, 'Trainer updated successfully', 'success');
    },
    onError: () => {
      toastFunction(toast, 'Trainer not updated', 'error');
    },
  });

  const {
    data: timeSlotData,
    isLoading: timeSlotIsLoading,
    isError: timeSlotIsError,
    error: timeSlotError,
    mutate: slotMutate,
  } = useMutation(
    (body) => makePostRequest(EDIT_TRAINER_TIMESLOTS(trainerId), body),
    {
      onSuccess: (res) => {
        setRemoveTimeSlot(true);
      },
      onError: () => {
        setRemoveTimeSlot(false);
        toastFunction(
          toast,
          'The time slot cannot be removed because it is currently ongoing',
          'error',
        );
      },
    },
  );

  const CheckTimeSlot = (day, time) => {
    slotMutate({
      day,
      time,
    });
  };

  const format12HrsTime = (time) => {
    const [hours, minutes] = time.split(':');
    const formattedHours = (parseInt(hours) % 12 || 12)
      .toString()
      .padStart(2, '0');
    const period = parseInt(hours) < 12 ? 'AM' : 'PM';
    return `${formattedHours}:${minutes} ${period}`;
  };

  const handleAddSlot = async () => {
    if (await trigger(['day', 'start_time', 'end_time'])) {
      const day = watch('day').label;
      const startTime = watch('start_time');
      const endTime = watch('end_time');

      const startMinutes = parseInt(startTime.split(':')[1]);
      if (startMinutes % 15 !== 0) {
        toastFunction(
          toast,
          'Please select a start time in 15-minute intervals',
          'error',
        );
        return;
      }

      const newSlot = `${format12HrsTime(startTime)} - ${format12HrsTime(
        endTime,
      )}`;

      setAvailability((prevAvailability) => {
        const updatedAvailability = { ...prevAvailability };
        if (updatedAvailability[day]) {
          // Check if the slot already exists
          if (updatedAvailability[day].includes(newSlot)) {
            toastFunction(toast, 'Time slot already exists', 'error');
            return prevAvailability; // No update needed
          }
          updatedAvailability[day].push(newSlot);
        } else {
          updatedAvailability[day] = [newSlot];
        }

        return updatedAvailability;
      });

      reset({
        day: '',
        start_time: '',
        end_time: '',
      });
    }
  };

  const sortedAvailability = Object.entries(availability).sort((a, b) => {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return daysOfWeek.indexOf(a[0]) - daysOfWeek.indexOf(b[0]);
  });

  const handleStartTimeChange = (event) => {
    const selectedStartTime = event.target.value;
    const [hours, minutes] = selectedStartTime.split(':').map(Number);

    let newHours = hours + 1;
    const newMinutes = minutes;

    if (newHours === 24) {
      newHours = 0;
    } else if (newHours > 24) {
      newHours -= 24;
    }

    const newEndTime = `${String(newHours).padStart(2, '0')}:${String(
      newMinutes,
    ).padStart(2, '0')}`;
    setValue('end_time', newEndTime);
  };

  const handleRemoveTimeSlot = (day, index) => {
    setAvailability((prevAvailability) => {
      const updatedAvailability = { ...prevAvailability };
      updatedAvailability[day].splice(index, 1);

      if (updatedAvailability[day].length === 0) {
        delete updatedAvailability[day];
      }

      return updatedAvailability;
    });
    setRemoveTimeSlot(false);
  };

  useEffect(() => {
    if (removeTimeSlot) handleRemoveTimeSlot(day, timeSlotId);
  }, [removeTimeSlot]);

  const pageBreadCrumbDetail = [
    {
      name: 'Availability',
      link: TEACHER_DASHBOARD_AVAILABILITY,
    },
    {
      name: 'My Availability',
      link: TEACHER_DASHBOARD_MANAGE_AVAILABILITY,
    },
  ];

  const onSubmit = async () => {
    if (Object.keys(availability).length === 0) {
      toastFunction(toast, 'Select Time Slots', 'error');
      return;
    }
    trainerMutate({
      name: trainerData?.name,
      phone: trainerData?.phone,
      email: trainerData?.email,
      gender: trainerData?.gender,
      age: trainerData?.age,
      experience: trainerData?.experience,
      age_group_taken: trainerData?.age_group_taken,
      genders_taken: trainerData?.genders_taken,
      courses_and_grades: trainerData?.courses_and_grades,
      availability,
      status: trainerData?.status,
      password: trainerData?.password,
    });
  };

  const cancelHandelr = () => {
    setAvailability({});
  };

  return (
    <TeacherDashboardLayout breadCrumb={pageBreadCrumbDetail}>
      <AppLoader isLoading={trainerIsLoading}>
        <Flex flexDirection="column" mb="30px">
          <ProgramTitleCard title="Manage Availability" />
          <Flex
            flexDirection="column"
            gap="20px"
            width="100%"
            bg={appColors.brandWhite[900]}
          >
            <Text
              fontSize={isMobile ? '16px' : '24px'}
              fontWeight={600}
              color={appColors.brandSecondary[500]}
            >
              Day & Timings
            </Text>
            <Flex
              width="100%"
              gap={5}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Flex width={isMobile ? '100%' : '40%'} flexDirection="column">
                <AppSelectV3
                  options={MetaData?.data?.meta?.days}
                  label="Day"
                  name="day"
                  control={control}
                  {...register('day', {
                    required: 'This field is required',
                  })}
                  isError={errors?.day}
                  isRequired
                />
              </Flex>
              <Flex
                width={isMobile ? '100%' : '60%'}
                gap={5}
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <Flex width={isMobile ? '100%' : '80%'} gap={isMobile ? 2 : 3}>
                  <Flex width="50%">
                    <AppInputV3
                      label="Time"
                      type="time"
                      placeholder="Start Time"
                      name="start_time"
                      {...register('start_time', {
                        required: 'This field is required',
                      })}
                      isRequired
                      isError={errors?.start_time}
                      onInputChange={handleStartTimeChange}
                    />
                  </Flex>
                  <Flex width="50%" mt="29px">
                    <AppInputV3
                      type="time"
                      name="end_time"
                      {...register('end_time', {
                        required: 'This field is required',
                      })}
                      isError={errors?.end_time}
                      readonly
                      isRequired
                    />
                  </Flex>
                </Flex>
                <Flex width={isMobile ? '100%' : '20%'}>
                  <AppButton
                    title="+ Add"
                    variant="solid"
                    customStyles={{
                      mt: isMobile ? '0px' : '29px',
                      width: isMobile ? '100%' : 'auto',
                      fontSize: isMobile ? '13px' : '16px',
                    }}
                    onClick={handleAddSlot}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex
              width="100%"
              flexDirection="column"
              gap={5}
              borderTop={`1px solid ${appColors.brandGrey[200]}`}
              display={
                sortedAvailability.length > 0 && !isMobile ? 'flex' : 'none'
              }
            >
              <TableContainer>
                <Table colorScheme="facebook">
                  <Thead>
                    <Tr>
                      <Th py="20px">
                        <Text
                          color={appColors.brandGrey[900]}
                          fontSize="12px"
                          fontWeight={700}
                          pl="30px"
                        >
                          DAYS
                        </Text>
                      </Th>
                      <Th>
                        <Text
                          color={appColors.brandGrey[900]}
                          fontSize="12px"
                          fontWeight={700}
                        >
                          TIMINGS
                        </Text>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {sortedAvailability.map(([day, slots]) => (
                      <Tr width="100%" key={day}>
                        <Td width="40%">
                          <Text pl="30px">{day}</Text>
                        </Td>
                        <Td width="60%">
                          <Flex gap={5} flexWrap="wrap">
                            {slots.map((slot, i) => (
                              <Flex
                                key={i}
                                width="auto"
                                padding="7px 14px"
                                bg={appColors.brandGrey[150]}
                                borderRadius="4px"
                                border={`1px solid ${appColors.brandGrey[600]}`}
                                gap={2}
                              >
                                <Text color={appColors.brandGrey[500]}>
                                  {slot}
                                </Text>
                                <Box
                                  cursor="pointer"
                                  color={appColors.brandGrey[500]}
                                  onClick={() => {
                                    CheckTimeSlot(day, slot);
                                    setTimeSlotId(i);
                                    setDay(day);
                                  }}
                                >
                                  &#10006;
                                </Box>
                              </Flex>
                            ))}
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>
            <Flex
              flexDirection="column"
              gap={5}
              display={isMobile ? 'flex' : 'none'}
            >
              <Text fontSize="13px" fontWeight={600}>
                Selected Days & Timings
              </Text>
              {sortedAvailability.map(([day, slots]) => (
                <Flex
                  flexDirection="column"
                  gap={3}
                  borderBottom={`1px solid ${appColors.brandGrey[600]}`}
                  paddingBottom="20px"
                >
                  <Text fontSize="13px" fontWeight={600}>
                    {day}
                  </Text>
                  <Flex gap={2} flexWrap="wrap">
                    {slots.map((slot, i) => (
                      <Flex
                        key={i}
                        width="auto"
                        padding="7px 10px"
                        fontSize="12px"
                        bg={appColors.brandGrey[150]}
                        borderRadius="4px"
                        border={`1px solid ${appColors.brandGrey[600]}`}
                        gap={2}
                      >
                        <Text color={appColors.brandGrey[500]}>{slot}</Text>
                        <Box
                          cursor="pointer"
                          color={appColors.brandGrey[500]}
                          onClick={() => {
                            CheckTimeSlot(day, slot);
                            setTimeSlotId(i);
                            setDay(day);
                          }}
                        >
                          &#10006;
                        </Box>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              ))}
            </Flex>
            <Flex
              width="100%"
              justifyContent={isMobile ? 'flex-start' : 'flex-end'}
              gap={5}
            >
              <Flex
                width={isMobile ? '50%' : 'auto'}
                alignItems="center"
                justifyContent="center"
                onClick={cancelHandelr}
              >
                <Text
                  color={appColors.brandSecondary[500]}
                  cursor="pointer"
                  fontWeight={600}
                  fontSize={isMobile ? '13px' : '16px'}
                >
                  Cancel
                </Text>
              </Flex>
              <AppButton
                title="Update Availability"
                variant="solid"
                customStyles={{
                  m: '0',
                  width: isMobile ? '50%' : 'auto',
                  fontSize: isMobile ? '13px' : '16px',
                }}
                onClick={onSubmit}
              />
            </Flex>
          </Flex>
        </Flex>
      </AppLoader>
    </TeacherDashboardLayout>
  );
}

export default TeacherManageAvailability;
