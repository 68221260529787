import AppText from 'version-two/components/Typography/AppText';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import { Image, useDisclosure, useBreakpointValue } from '@chakra-ui/react';
import { CopyContentIcon } from 'version-two/assets';
import StudentProgramDetailDrawable from 'version-two/components/StudentProgramDetailDrawable/StudentProgramDetailDrawable';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GET_STUDENT_LIVE_CLASS_DETAIL_DATA } from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import { useEffect, useState } from 'react';
import {
  MAKE_STUDENT_PROGRAM_DETAIL,
  MAKE_STUDENT_PROGRAM_DETAIL_CONTENT,
} from 'version-two/navigation/routes';

function StudentProgramContent() {
  const [activeModule, setActiveModule] = useState(null);
  // const [contentIndex, setContentIndex] = useState(null);
  const {
    isOpen: programDrawableIsOpen,
    onOpen: programDrawableOnOpen,
    onClose: programDrawableOnClose,
  } = useDisclosure();

  const navigate = useNavigate();
  const { programSlug, moduleSlug } = useParams();

  const { data, isLoading, isRefetching } = useQuery(
    GET_STUDENT_LIVE_CLASS_DETAIL_DATA(programSlug),
    () => makeGetRequest(GET_STUDENT_LIVE_CLASS_DETAIL_DATA(programSlug)),
  );

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const handleNavigateDrawable = (module) => {
    navigate(MAKE_STUDENT_PROGRAM_DETAIL_CONTENT(programSlug, module?.id));
    // setContentIndex(index + 1);
    // console.log('show Index out', index);
    // console.log('module out', module);
  };
  const handleDrawableOpen = (module) => {
    programDrawableOnOpen();
    setActiveModule(module);
  };

  const handleDrawableClose = () => {
    navigate(MAKE_STUDENT_PROGRAM_DETAIL(programSlug));
    programDrawableOnClose();
  };

  useEffect(() => {
    if (data && moduleSlug) {
      data.data.modules.some((moduleData) => {
        if (moduleData.id === moduleSlug) {
          handleDrawableOpen(moduleData);
          return true;
        }
        return false;
      });
    }

    if (!moduleSlug) {
      programDrawableOnClose();
    }
  }, [moduleSlug, data]);

  return (
    <AppBox>
      <AppLoader isLoading={isLoading || isRefetching}>
        {!(isLoading || isRefetching) && (
          <>
            {/* Listing */}
            {/* <AppText title="Unlocked Modules" variant="headerLargeLight" /> */}

            {data.data.modules.map((enabledModulesData, index) => (
              <AppFlex
                key={enabledModulesData.id}
                variant="moduleListingCard"
                customStyles={{ justifyContent: 'space-between' }}
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  isMobile && handleNavigateDrawable(enabledModulesData);
                }}
              >
                <AppBox>
                  <AppFlex>
                    <AppText
                      title={`${data.data.live_class_v2.identity}`}
                      variant="orangeXXS"
                    />
                    <AppText title="/" size="xxs" customStyles={{ mx: 1 }} />
                    <AppText title={`MODULE ${index + 1}`} variant="lightXXS" />
                  </AppFlex>
                  <AppText
                    title={enabledModulesData.identity}
                    size="sm"
                    onClick={() =>
                      handleNavigateDrawable(enabledModulesData, index)
                    }
                    customStyles={{
                      cursor: 'pointer',
                      width: 'fit-content',
                    }}
                  />
                  {/* <AppText
                    title={`Unlocked on ${getFormattedDate(
                      enabledModulesData?.created,
                    )}`}
                    variant="lightXXS"
                    customStyles={{
                      fontWeight: fontWeights.normal,
                    }}
                  /> */}
                </AppBox>

                <AppFlex
                  customStyles={{ alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {
                    handleNavigateDrawable(enabledModulesData, index);
                  }}
                >
                  <Image src={CopyContentIcon} mr={2} alt="" />
                  <AppText
                    title={enabledModulesData.content_count}
                    variant="lightXS"
                  />
                </AppFlex>
              </AppFlex>
            ))}

            {activeModule && (
              <StudentProgramDetailDrawable
                moduleName={`${data.data.live_class_v2.identity}`}
                isOpen={programDrawableIsOpen}
                onClose={handleDrawableClose}
                activeModule={activeModule}
                wholeData={data}
                // contentIndex={contentIndex}
              />
            )}
          </>
        )}
      </AppLoader>
    </AppBox>
  );
}

export default StudentProgramContent;
