// @ts-nocheck

export const requestNotificationPermission = async () => {
  const promise = new Promise((resolve, reject) => {
    if (!('Notification' in window)) {
      // Check if the browser supports notifications
      reject(new Error('This browser does not support desktop notification'));
    } else if (Notification.permission === 'granted') {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      resolve();
      // …
    } else if (Notification.permission !== 'denied') {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          resolve();
          // …
        } else {
          reject(new Error('Permission denied'));
        }
      });
    }
  });
  return promise;
};
