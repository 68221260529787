import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import AppButton from 'version-two/components/Typography/AppButton';
import appColors from 'version-two/theme/appColors';
import { fontWeights } from 'version-two/theme/fonts';
import { appBorderRadius } from 'version-two/theme/sizes';
import {
  CLASS_DONE,
  TEACHER_CLASS_RECORDINGS_META,
} from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import { useForm } from 'react-hook-form';
import AppSelectV3 from '../AppSelect/AppSelectV3';
import { toastFunction } from '../CommonFunctions/CommonFunctions';

function TeacherClassRecordingDialog({
  ClassID,
  isOpen,
  onClose,
  leastDestructiveRef,
  BatchClassRefetch,
}) {
  const { programSlug } = useParams();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    watch,
    control,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const { data: MetaData } = useQuery(
    [TEACHER_CLASS_RECORDINGS_META(programSlug)],
    () => makeGetRequest(TEACHER_CLASS_RECORDINGS_META(programSlug)),
  );

  const { mutate } = useMutation(
    (body) => makePostRequest(CLASS_DONE(ClassID), body),
    {
      onSuccess: () => {
        onClose();
        toastFunction(toast, 'Class marked as completed', 'success');
        BatchClassRefetch();
        reset({
          zoom_recording: '',
        });
      },
      onError: (error) => {
        if (error?.response?.data?.non_field_errors) {
          error?.response?.data?.non_field_errors?.map((err) =>
            toastFunction(toast, err, 'error'),
          );
        } else {
          toastFunction(toast, 'Failed', 'error');
        }
      },
    },
  );

  const onSubmit = async () => {
    if (await trigger()) {
      mutate({
        selected_record_id: watch('zoom_recording').value,
      });
    }
  };

  const onCloseHandler = () => {
    onClose();
    reset({
      zoom_recording: '',
    });
  };

  const alertDialogSize = useBreakpointValue({ base: 'full', sm: 'sm' });
  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={isOpen}
      onClose={onCloseHandler}
      isCentered
      size={alertDialogSize}
    >
      <AlertDialogOverlay />

      <AlertDialogContent
        style={{
          borderRadius: appBorderRadius.md,
          paddingTop: 10,
        }}
        variant="uploadDialog"
      >
        <AlertDialogHeader>
          <AppText
            title="Add Zoom Recording"
            size="md"
            customStyles={{
              fontWeight: fontWeights.bold,
            }}
          />
        </AlertDialogHeader>
        <AlertDialogCloseButton
          color={appColors.brandGrey['300']}
          mt={3}
          mr={2}
        />

        <AlertDialogBody>
          <Flex width="100%" flexDirection="column" gap={5}>
            <AppSelectV3
              options={MetaData?.data}
              label="Zoom Recording"
              name="zoom_recording"
              control={control}
              {...register('zoom_recording', {
                required: 'This field is required',
              })}
              isError={errors?.class}
              isRequired
            />
          </Flex>
        </AlertDialogBody>
        <AppFlex
          variant="alertDialogFooter"
          customStyles={{ justifyContent: 'flex-end' }}
        >
          <AppButton
            title="Submit"
            variant="solid"
            onClick={handleSubmit(onSubmit)}
            customStyles={{
              marginTop: 0,
            }}
          />
        </AppFlex>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export default TeacherClassRecordingDialog;
