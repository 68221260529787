/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const useSchedulerHandler = ({ schedulerData }) => {
  const [state, setState] = React.useState({
    data: schedulerData,
    currentViewName: 'Month',
    currentDate: new Date(),
  });

  const [params, setParams] = useSearchParams({});

  const currentDateChange = (currentDate) => {
    setState({ ...state, currentDate });
  };

  const currentViewNameChange = (currentViewName) => {
    setState({ ...state, currentViewName });
  };

  useEffect(() => {
    const month =
      new Date(state?.currentDate).getMonth() < 9
        ? `0${new Date(state?.currentDate).getMonth() + 1}`
        : new Date(state?.currentDate).getMonth() + 1;
    setParams({
      year: new Date(state?.currentDate).getFullYear(),
      month,
    });
  }, [state?.currentDate]);

  return {
    state,
    setState,
    currentDateChange,
    currentViewNameChange,
  };
};

export default useSchedulerHandler;
