import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/nunito/200.css';
import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/600.css';
import '@fontsource/nunito/700.css';
import '@fontsource/nunito/800.css';
import '@fontsource/nunito/900.css';

import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
// import {getVoxguruVersion} from 'appConfig';
import { UIKitSettingsBuilder } from '@cometchat/uikit-shared';
import {
  COMET_CHAT_APP_ID,
  COMET_CHAT_APP_REGION,
  COMET_CHAT_AUTH_KEY,
} from 'version-two/utils/constants';
import { CometChatUIKit } from '@cometchat/chat-uikit-react';
// eslint-disable-next-line import/namespace, import/default
import App from 'App';
import theme from './version-two/theme';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
      // staleTime: Infinity,
    },
  },
});

const UIKitSettings = new UIKitSettingsBuilder()
  .setAppId(COMET_CHAT_APP_ID)
  .setRegion(COMET_CHAT_APP_REGION)
  .setAuthKey(COMET_CHAT_AUTH_KEY)
  .subscribePresenceForAllUsers()
  .build();

CometChatUIKit.init(UIKitSettings).then(() => {
  // eslint-disable-next-line no-console
  console.log('CometChat Initialization Successfull');
});

if ('serviceWorker' in navigator) {
  // console.log('serviceWorker');
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')

    .then(() => {
      // console.log('registration', registration);
      // console.log('Registration succlgcessful, scope is:', registration.scope);
    })
    .catch(() => {});
}

root.render(
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </QueryClientProvider>,
);
