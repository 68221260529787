/* eslint-disable radix */
import { Flex, Text, useBreakpointValue, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { EDIT_TRAINER, TRAINER_META, VIEW_TRAINER } from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import makePutRequest from 'version-two/api/utils/makePutRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import AppSelectV3 from 'version-two/components/AppSelect/AppSelectV3';
import ProgramTitleCard from 'version-two/components/ProgramTitleCard/ProgramTitleCard';
import CourseAndGrade from 'version-two/components/TeacherProfile/CourseAndGrade';
import AppButton from 'version-two/components/Typography/AppButton';
import AppInputV3 from 'version-two/components/Typography/AppInputV3';
import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
import { toastFunction } from 'version-two/components/CommonFunctions/CommonFunctions';
import { TEACHER_PROFILE } from 'version-two/navigation/routes';
import appColors from 'version-two/theme/appColors';
import useAppStore from 'version-two/store';

function TeacherProfile() {
  const toast = useToast();
  const [isEdit, setIsEdit] = useState(false);
  const [classListing, setClassListing] = useState([]);
  const [courses, setCourses] = useState({});
  const trainerId = useAppStore.getState().trainer_id;
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const {
    register,
    handleSubmit,
    watch,
    control,
    trigger,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm();

  const pageBreadCrumbDetail = [
    {
      name: 'Trainer Profile',
      link: TEACHER_PROFILE,
    },
  ];

  function formattedCourseandGrade(input) {
    const output = {};
    let counter = 1;

    input.forEach((item) => {
      const combined = `${item.class}-${item.grade}`;
      output[counter] = combined;
      counter += 1;
    });

    return output;
  }

  function JoinInput(input) {
    const values = input?.map((item) => item.value);
    const output = values?.join(', ');
    return output;
  }

  function ConvertToObject(input) {
    const items = input.split(', ');
    const result = items.map((item) => ({
      label: item,
      value: item,
    }));
    return result;
  }

  function SplitCourseAndGrade(input) {
    const output = Object.values(input).map((value) => {
      const [className, grade] = value.split('-');
      return {
        class: className,
        grade,
        courseName: value,
      };
    });
    return output;
  }

  const { data: MetaData } = useQuery([TRAINER_META], () =>
    makeGetRequest(TRAINER_META),
  );

  const {
    data: trainerData,
    isLoading: trainerIsLoading,
    refetch,
  } = useQuery(
    [VIEW_TRAINER(trainerId)],
    () => makeGetRequest(VIEW_TRAINER(trainerId)),
    {
      onSuccess: (res) => {
        setValue('data', res);
        const exp = res?.experience.replace(/\s*years\s*/i, '');
        setValue('data.experience', exp);
        setValue('data.confirm_password', res?.password);
        setValue('data.gender', {
          label: res?.gender,
          value: res?.gender,
        });
        setValue('data.age_group_taken', ConvertToObject(res?.age_group_taken));
        setValue('data.genders_taken', ConvertToObject(res?.genders_taken));
        setClassListing(SplitCourseAndGrade(res?.courses_and_grades));
      },
      enabled: Boolean(trainerId),
    },
  );

  const { mutate } = useMutation(
    (body) => makePutRequest(EDIT_TRAINER(trainerId), body),
    {
      onSuccess: () => {
        setIsEdit(false);
        toastFunction(toast, 'Trainer updated successfully', 'success');
      },
      onError: () => {
        toastFunction(toast, 'Trainer not updated', 'error');
      },
    },
  );

  useEffect(() => {
    refetch();
    clearErrors('data');
  }, [isEdit]);

  const transformedCourses = Object.keys(courses).reduce((acc, course) => {
    const courseGrades = courses[course];
    courseGrades.forEach((grade) => {
      acc.push({ class: course, grade, courseName: `${course}-${grade}` });
    });
    return acc;
  }, []);

  const onSubmit = async () => {
    if (await trigger()) {
      if (!classListing.length > 0) {
        toastFunction(toast, 'Select Course and Grade', 'error');
        return;
      }
      mutate({
        name: watch('data.name'),
        phone: watch('data.phone'),
        email: watch('data.email'),
        gender: watch('data.gender').value,
        age: parseInt(watch('data.age')),
        experience: `${watch('data.experience')} Years`,
        age_group_taken: JoinInput(watch('data.age_group_taken')),
        genders_taken: JoinInput(watch('data.genders_taken')),
        courses_and_grades: formattedCourseandGrade(transformedCourses),
        availability: trainerData?.availability,
        status: trainerData?.status,
        password: watch('data.password'),
      });
    }
  };

  const cancelHandelr = () => {
    reset({
      name: '',
      email: '',
      gender: '',
      phone: '',
      age: '',
      experience: '',
      age_group_taken: '',
      genders_taken: '',
    });
    setClassListing([]);
    setIsEdit(false);
    window.location.reload();
  };

  return (
    <TeacherDashboardLayout breadCrumb={pageBreadCrumbDetail}>
      <AppLoader isLoading={trainerIsLoading}>
        <ProgramTitleCard title="Trainer Profile" />
        <Flex flexDirection="column" gap={5} mb="30px">
          <Flex width="100%" justifyContent="space-between">
            <Text
              fontSize="24px"
              fontWeight="700"
              color={appColors.brandSecondary[500]}
            >
              Trainer Info
            </Text>
            {!isEdit && (
              <AppButton
                title="Edit Profile"
                variant="solid"
                customStyles={{ m: '0', display: isMobile ? 'none' : 'block' }}
                onClick={() => setIsEdit(true)}
              />
            )}
          </Flex>
          <Flex width="100%" gap={5} flexDirection="column">
            <Flex
              width="100%"
              gap={5}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Flex width={isMobile ? '100%' : '50%'}>
                <AppInputV3
                  label="Name"
                  type="text"
                  name="name"
                  {...register('data.name')}
                  isError={errors?.data?.name}
                  readonly={!isEdit}
                />
              </Flex>
              <Flex width={isMobile ? '100%' : '50%'}>
                <AppSelectV3
                  options={MetaData?.data?.meta?.gender_taken}
                  label="Gender"
                  name="gender"
                  control={control}
                  width="100%"
                  {...register('data.gender')}
                  isError={errors?.data?.gender}
                  readonly={!isEdit}
                />
              </Flex>
            </Flex>
            <Flex
              width="100%"
              gap={5}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Flex
                width={isMobile ? '100%' : '50%'}
                flexDirection={isMobile ? 'column' : 'row'}
                gap={5}
              >
                <AppInputV3
                  label="Phone"
                  type="text"
                  name="phone"
                  placeholder="+9198384*****"
                  {...register('data.phone', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/,
                      message:
                        'Enter the correct phone number with country code',
                    },
                  })}
                  isError={errors?.data?.phone}
                  readonly={!isEdit}
                />
              </Flex>
              <Flex
                width={isMobile ? '100%' : '50%'}
                flexDirection={isMobile ? 'column' : 'row'}
                gap={5}
              >
                <AppInputV3
                  label="Email"
                  type="email"
                  name="email"
                  {...register('data.email', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'Invalid email',
                    },
                  })}
                  isError={errors?.data?.email}
                  readonly
                />
              </Flex>
            </Flex>
            <Flex
              width="100%"
              gap={5}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Flex
                width={isMobile ? '100%' : '50%'}
                flexDirection={isMobile ? 'column' : 'row'}
                gap={5}
              >
                <AppInputV3
                  label="Age"
                  type="number"
                  name="age"
                  {...register('data.age', {
                    required: 'This field is required',
                    min: {
                      value: 1,
                      message: 'Age must be at least 1',
                    },
                    max: {
                      value: 99,
                      message: 'Age must be at most 99',
                    },
                    validate: {
                      notZero: (value) => value !== 0 || "Age can't be 0",
                    },
                  })}
                  isError={errors?.data?.age}
                  readonly={!isEdit}
                />
              </Flex>
              <Flex
                width={isMobile ? '100%' : '50%'}
                flexDirection={isMobile ? 'column' : 'row'}
                gap={5}
              >
                <AppInputV3
                  label="Experience"
                  subLabel="(In Years)"
                  type="number"
                  name="experience"
                  {...register('data.experience', {
                    required: 'This field is required',
                    min: {
                      value: 1,
                      message: 'Experience must be at least 1',
                    },
                    max: {
                      value: 99,
                      message: 'Experience must be at most 99',
                    },
                    validate: {
                      notZero: (value) =>
                        value !== 0 || "Experience can't be 0",
                    },
                  })}
                  isError={errors?.data?.experience}
                  readonly={!isEdit}
                />
              </Flex>
            </Flex>
            <Flex
              width="100%"
              gap={5}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Flex
                width={isMobile ? '100%' : '50%'}
                flexDirection={isMobile ? 'column' : 'row'}
                gap={5}
              >
                <AppInputV3
                  label="Password"
                  type="password"
                  name="password"
                  {...register('data.password', {
                    required: 'This field is required',
                    minLength: {
                      value: 8,
                      message: 'Invalid password',
                    },
                  })}
                  isError={errors?.data?.password}
                  readonly={!isEdit}
                />
              </Flex>
              <Flex
                width={isMobile ? '100%' : '50%'}
                flexDirection={isMobile ? 'column' : 'row'}
                gap={5}
              >
                <AppInputV3
                  label="Confirm Password"
                  type="password"
                  name="confirm_password"
                  {...register('data.confirm_password', {
                    required: 'This field is required',
                    minLength: {
                      value: 8,
                      message: 'Invalid Password',
                    },
                    validate: (value) =>
                      value === watch('data.password') ||
                      'Passwords do not match',
                  })}
                  isError={errors?.data?.confirm_password}
                  readonly={!isEdit}
                />
              </Flex>
            </Flex>
          </Flex>
          <Text
            fontSize="24px"
            fontWeight="700"
            color={appColors.brandSecondary[500]}
          >
            Specializations
          </Text>
          <Flex
            width="100%"
            gap={5}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Flex
              width={isMobile ? '100%' : '50%'}
              flexDirection="column"
              gap={5}
            >
              <AppSelectV3
                options={MetaData?.data?.meta?.kid_or_adult}
                label="Age Group"
                name="age_group_taken"
                placeholder="Multi-select kids, adults"
                control={control}
                {...register('data.age_group_taken', {
                  required: 'This field is required',
                })}
                isError={errors?.data?.age_group_taken}
                isMulti
                readonly={!isEdit}
              />
            </Flex>
            <Flex
              width={isMobile ? '100%' : '50%'}
              flexDirection="column"
              gap={5}
            >
              <AppSelectV3
                options={MetaData?.data?.meta?.gender_taken}
                label="Gender"
                name="genders_taken"
                placeholder="Multi-select male, female"
                control={control}
                {...register('data.genders_taken', {
                  required: 'This field is required',
                })}
                isError={errors?.data?.genders_taken}
                isMulti
                readonly={!isEdit}
              />
            </Flex>
          </Flex>
          <CourseAndGrade
            isEdit={isEdit}
            classListing={classListing}
            setClassListing={setClassListing}
            courses={courses}
            setCourses={setCourses}
          />
          {!isEdit && (
            <AppButton
              title="Edit Profile"
              variant="solid"
              customStyles={{
                m: '0',
                width: '100%',
                display: isMobile ? 'block' : 'none',
                fontSize: isMobile ? '13px' : '16px',
              }}
              onClick={() => setIsEdit(true)}
            />
          )}
          <Flex
            width="100%"
            justifyContent={isMobile ? 'flex-start' : 'flex-end'}
            gap={5}
            display={isEdit ? 'flex' : 'none'}
          >
            <Flex
              width={isMobile ? '50%' : 'auto'}
              alignItems="center"
              justifyContent="center"
              onClick={cancelHandelr}
            >
              <Text
                color={appColors.brandSecondary[500]}
                cursor="pointer"
                fontWeight={600}
                fontSize={isMobile ? '13px' : '16px'}
              >
                Cancel
              </Text>
            </Flex>
            <AppButton
              title="Save Changes"
              variant="solid"
              customStyles={{
                m: '0',
                width: isMobile ? '50%' : 'auto',
                fontSize: isMobile ? '13px' : '16px',
              }}
              onClick={handleSubmit(onSubmit)}
            />
          </Flex>
        </Flex>
      </AppLoader>
    </TeacherDashboardLayout>
  );
}

export default TeacherProfile;
