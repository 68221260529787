import { UIKitSettingsBuilder } from '@cometchat/chat-uikit-react';

import {
  COMET_CHAT_APP_ID,
  COMET_CHAT_APP_REGION,
  COMET_CHAT_AUTH_KEY,
} from 'version-two/utils/constants';

const useInitiateCometChat = () => {
  const UIKitSettings = new UIKitSettingsBuilder()
    .setAppId(COMET_CHAT_APP_ID)
    .setRegion(COMET_CHAT_APP_REGION)
    .setAuthKey(COMET_CHAT_AUTH_KEY)
    .subscribePresenceForAllUsers()
    .build();

  return { UIKitSettings };
};

export default useInitiateCometChat;
