import AppVideoPlayer from 'version-two/pages/common/AppVideoPlayer/AppVideoPlayer';
import { useParams } from 'react-router-dom';
import {
  CLASS_DETAILS,
  GET_TEACHER_ID_SCHEDULE,
  TEACHER_BATCH_VERSION,
} from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import { useQuery } from 'react-query';

function AppClassRecordingsWrapper() {
  const { programSlug, weekId } = useParams();

  const { data: batchDetail, isLoading: batchIsLoading } = useQuery(
    TEACHER_BATCH_VERSION(programSlug),
    () => makeGetRequest(TEACHER_BATCH_VERSION(programSlug)),
  );

  const { data: programDetail, isLoading: programDetailIsLoading } = useQuery(
    GET_TEACHER_ID_SCHEDULE(programSlug),
    () => makeGetRequest(GET_TEACHER_ID_SCHEDULE(programSlug)),
    {
      enabled: !batchIsLoading && batchDetail?.data?.api === 'old',
    },
  );

  const { data: classData, isLoading: classIsLoading } = useQuery(
    [CLASS_DETAILS(weekId)],
    () => makeGetRequest(CLASS_DETAILS(weekId)),
    {
      enabled: !batchIsLoading && batchDetail?.data?.api === 'new',
    },
  );

  const targetedWeek = programDetail?.data?.schedules.filter(
    (weekData) => weekData.id === weekId,
  );

  let videoUrl;
  if (!batchIsLoading) {
    if (batchDetail?.data?.api === 'new') {
      if (!classIsLoading) {
        videoUrl = classData?.data?.file;
      }
    }
    if (batchDetail?.data?.api === 'old') {
      if (!programDetailIsLoading) {
        videoUrl = targetedWeek[0]?.file;
      }
    }
  }

  return (
    videoUrl && (
      <AppVideoPlayer
        url={videoUrl}
        loading={batchIsLoading || programDetailIsLoading || classIsLoading}
      />
    )
  );
}

export default AppClassRecordingsWrapper;
