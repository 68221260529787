// import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
// import ProgramTitleCard from 'version-two/components/ProgramTitleCard/ProgramTitleCard';
import { Box, Link } from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
// import { CopyContentIcon, ManageLockIcon } from 'version-two/assets';
// import GroupUserProfileImage from 'version-two/components/GroupUserProfileImage/GroupUserProfileImage';
import { useQuery } from 'react-query';
import { TEACHER_ZOOM_DETAILS } from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
// import AppLoader from 'version-two/components/AppLoader/AppLoader';
import { useParams } from 'react-router-dom';
// import { useEffect, useState } from 'react';
// import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
// import { Select } from 'chakra-react-select';
// import useStudentSearch from 'version-two/hooks/useStudentSearch';
// import {
//   MAKE_TEACHER_PROGRAM_DETAIL,
//   MAKE_TEACHER_PROGRAM_DETAIL_CONTENT,
//   TEACHER_DASHBOARD,
// } from 'version-two/navigation/routes';
// import appColors from 'version-two/theme/appColors';
// import useAppStore from 'version-two/store';

function TeacherZoomDetails() {
  // const { isAuthenticated } = useAppStore((state) => state);

  // const navigate = useNavigate();
  const { programSlug } = useParams();

  const { data: ZoomData } = useQuery(TEACHER_ZOOM_DETAILS(programSlug), () =>
    makeGetRequest(TEACHER_ZOOM_DETAILS(programSlug)),
  );

  return (
    <>
      <Box mt="24px" pb="42px" borderBottom="1px solid #80808042">
        <AppText title="Zoom login details" mb="26px" variant="zoomHead" />
        <Box my="15px">
          <AppText
            variant="zoomSubHead"
            title={`Username - ${ZoomData?.data?.zoom_username}`}
          />
          <AppText
            variant="zoomSubHead"
            title={`Password - ${ZoomData?.data?.zoom_password}`}
          />
        </Box>
        <AppText
          variant="light"
          title="Note : Please ensure that you have logged into this zoom account before clicking on the link below"
          customStyles={{
            fontSize: { base: '11px', sm: '19px' },
          }}
        />
      </Box>

      <Box mt="47px" pb="60px" borderBottom="1px solid #80808042">
        <AppText
          title="Meeting link for your sessions"
          variant="zoomHead"
          customStyles={{
            marginBottom: '16px',
          }}
        />
        <Link href={ZoomData?.data?.zoom_meeting_link} isExternal>
          <AppText
            variant="lightXS"
            title={ZoomData?.data?.zoom_meeting_link}
            mb="33px"
            customStyles={{
              fontSize: { base: '10px', sm: '19px' },
              fontWeight: '600',
              cursor: 'pointer',
              color: '#8B5971',
            }}
          />
        </Link>
        <Box mt="15px">
          <AppText
            variant="zoomSubHead"
            title={`Meeting ID - ${ZoomData?.data?.zoom_meeting_id}`}
          />
          <AppText
            variant="zoomSubHead"
            title={`Passcode - ${ZoomData?.data?.zoom_passcode}`}
          />
        </Box>
      </Box>

      <Box mt="56px">
        <Box>
          <AppText title="Gmail Login" variant="zoomHead" mb="2px" />
        </Box>
        <AppText
          variant="lightXS"
          title="(If Zoom asks for OTP)"
          size="sm"
          mb="28px"
          customStyles={{
            fontSize: { base: '11px', sm: '19px' },
          }}
        />
        <Box my="15px">
          <AppText
            variant="zoomSubHead"
            title={`Username - ${ZoomData?.data?.google_username}`}
          />
          <AppText
            variant="zoomSubHead"
            title={`Password - ${ZoomData?.data?.google_password}`}
          />
        </Box>
      </Box>
    </>
  );
}

export default TeacherZoomDetails;
