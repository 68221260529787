import { CometChatUIKit } from '@cometchat/chat-uikit-react';
import useInitiateCometChat from 'version-two/hooks/useInitiateCometChat';
import { CometChatNotifications } from '@cometchat/chat-sdk-javascript';

const useLogoutCometChat = () => {
  const { UIKitSettings } = useInitiateCometChat();

  const logoutComet = () => {
    CometChatUIKit.init(UIKitSettings)
      .then(() => {
        CometChatUIKit.getLoggedinUser().then(async (user) => {
          if (user) {
            await CometChatNotifications.unregisterPushToken();
            CometChatUIKit.logout().then(() => {
              // eslint-disable-next-line
              // console.log("User logged out");
              localStorage.removeItem('fcmToken');
            });
          }
        });
      })
      .catch(() => {
        // eslint-disable-next-line
        // console.log("Failed to logout CometChat", err);
      });
  };

  return { logoutComet };
};

export default useLogoutCometChat;
