import { Box, Container } from '@chakra-ui/react';
import { useEffect } from 'react';
import AppStudentNavbar from 'version-two/components/AppStudentNavbar/AppStudentNavbar';
import { requestNotificationPermission } from 'version-two/components/CCConvoWithMessageWrapper/helpers';

function StudentDashboardLayout({ children }) {
  useEffect(() => {
    requestNotificationPermission();
  }, []);

  return (
    <Box>
      <AppStudentNavbar />
      <Container maxW="1000px">{children}</Container>
    </Box>
  );
}

export default StudentDashboardLayout;
