// Authentication
export const APP_TEACHER_LOGIN =
  '/api/live-class-app/teacher/auth/obtain-auth-token/';
export const APP_TEACHER_IS_AUTHENTICATED = `/api/live-class-app/teacher/auth/status/`;
export const APP_TEACHER_LOGOUT = `/api/live-class-app/teacher/auth/logout/`;

// Program Listing
export const GET_TEACHER_LIST_PROGRAMS =
  '/api/live-class-app-v2/teacher/home/batches/';
// '/api/live-class-app/teacher/home/teaching/programs/';

export const GET_TEACHER_ID_MODULE = (slug) =>
  `/api/live-class-app-v2/teacher/batches/${slug}/modules/`;

export const GET_TEACHER_ID_SESSION_FLOW = (slug) =>
  `/api/live-class-app-v2/teacher/batches/${slug}/session-flow/`;

export const GET_TEACHER_ID_SCHEDULE = (slug) =>
  `/api/live-class-app-v2/teacher/batches/${slug}/schedules/`;

// Program Detail
export const GET_TEACHER_LIVE_CLASS_DETAIL = (slug) =>
  `/api/live-class-app/teacher/live-class/${slug}/view-in-detail/`;

// Program Module Detail
export const GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL = (slug) =>
  `/api/live-class-app-v2/modules/${slug}/view-in-detail/`;

// Program Session Detail
export const GET_TEACHER_LIVE_CLASS_SESSION_IN_DETAIL = (slug) =>
  `/api/live-class-app-v2/session-flow/${slug}/view-in-detail/`;

// Get Students List for the Program
export const GET_ACTIVE_STUDENTS_LIST = (slug) =>
  `/api/live-class-app-v2/teacher/batches/${slug}/students/`;

export const GET_INACCESSIBLE_STUDENTS_LIST = (batchId, teacherContentId) =>
  `/api/live-class-app-v2/teacher-content/${teacherContentId}/${batchId}/share-access-bulk/`;

// View File
export const GET_FILE_DATA_TEACHER = (slug) =>
  `/api/live-class-app-v2/content/${slug}/view-in-detail/`;
// `/api/live-class-app/teacher/content/${slug}/view-in-detail/`;

// File Upload
export const LIVE_CLASS_TEACHER_FILE_UPLOAD = (slug) =>
  `/api/live-class-app-v2/modules/${slug}/create-teacher-content/`;
export const COMMON_FILE_UPLOAD = `/api/live-class-app/teacher/common/file/upload/`;

// File Delete
export const LIVE_CLASS_TEACHER_FILE_DELETE = (slug) =>
  `/api/live-class-app-v2/teacher-content/${slug}/delete/`;

// GET STUDENTS FOR MANAGE MODULE
export const GET_STUDENTS_FOR_MANAGE = (slug) =>
  `/api/live-class-app/teacher/live-class/module/${slug}/share-access-bulk/`;

export const UNLOCK_MODULES_BULK_ACCESS = (slug) =>
  `/api/live-class-app/teacher/live-class/module/${slug}/share-access-bulk/`;

// GET STUDENTS FOR SHARE NOTE

export const GET_STUDENT_FOR_SHARE_NOTE_ACCESS = (slug) =>
  `/api/live-class-app/teacher/teacher-content/${slug}/share-access-bulk/`;

export const SHARE_NOTE_BULK_ACCESS = (slug) =>
  `/api/live-class-app-v2/teacher-content/${slug}/share-access-bulk/`;

// Student Search
export const PROGRAM_DETAIL_STUDENT_SEARCH = (searchVal, liveClassId) =>
  // eslint-disable-next-line max-len
  `/api/live-class-app/teacher/my-students/search/?search=${searchVal}&live_classes_eligible=${liveClassId}`;

export const GET_INDIVIDUAL_STUDENT_PROGRAM_DETAIL = (programSlug, studentId) =>
  `/api/live-class-app/teacher/live-class/${programSlug}/student/${studentId}/actions-display/`;

export const INDIVIDUAL_STUDENT_MODULE_COURSE_LOCK =
  '/api/live-class-app/teacher/live-class/module/student-actions/lock-unlock/lock/';

export const INDIVIDUAL_STUDENT_MODULE_COURSE_UNLOCK =
  '/api/live-class-app/teacher/live-class/module/student-actions/lock-unlock/unlock/';

export const GET_TEACHER_CHAT_LISTING = `/api/live-class-app/teacher/home/chat-groups/`;

// New Trainer Api
export const GET_TRAINER_ID = (id) => `/api/crm/fetch-trainer-id/${id}/`;
export const TRAINER_CALENDAR_META = '/api/crm/trainers/calendar/meta/';
export const VIEW_TRAINER_CALENDAR = (id) => `/api/crm/trainer/calendar/${id}/`;
export const VIEW_TRAINER = (id) => `/api/crm/trainer/detail/${id}/`;
export const EDIT_TRAINER = (id) => `/api/crm/trainers/cud/${id}/`;
export const EDIT_TRAINER_COURSELIST = (id) =>
  `/api/crm/trainer/update-course/${id}/`;
export const EDIT_TRAINER_TIMESLOTS = (id) =>
  `/api/crm/trainer/update-time-slot/${id}/`;
export const TRAINER_META = '/api/crm/trainers/cud/meta/';
export const LIST_OF_CLASSES = (id) =>
  `/api/crm/class-list/against-batch/${id}/`;
export const CLASS_DONE = (id) => `/api/crm/class/mark-done/${id}/`;
export const CLASS_DETAILS = (id) => `/api/crm/class/detail/${id}/`;
export const MARK_ATTENDANCE = '/api/crm/class/mark-attendance/';
export const TRAINER_ADD_NOTE = '/api/crm/note/cud/';
export const TRAINER_DELETE_NOTE = (id) => `/api/crm/note/cud/${id}`;
export const CANCEL_CLASS = (id) => `/api/crm/cancel-class/${id}/`;
export const TEACHER_ZOOM_DETAILS = (id) =>
  `/api/live-class-app-v2/teacher/batch/zoom-details/${id}/`;
export const TEACHER_ACTIVE_STUDENTS = (id) =>
  `/api/live-class-app-v2/teacher/student-list/${id}/`;
export const TEACHER_BATCH_VERSION = (id) =>
  `/api/live-class-app-v2/teacher/decide-batch/${id}/`;
export const GET_NOTES_SHARABLE_STUDENTS = (id) =>
  `/api/crm/trainer-web-user-list-sharenote/${id}/`;
export const TEACHER_SHARE_NOTES = (id) =>
  `/api/crm/trainer-content-share/${id}/`;
export const TEACHER_CLASS_RECORDINGS_META = (id) =>
  `api/crm/fetch-zoom-recordings/${id}/`;
export const TEACHER_VIEW_BATCH_LIST_META = (id) =>
  `api/crm/single-trainer-calendar/meta/${id}`;
export const CONVERT_PDF_TO_IMAGE = `/api/live-class-app-v2/render-pdf/`;
