/* eslint-disable no-unused-vars */
import {
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import ProgramTitleCard from 'version-two/components/ProgramTitleCard/ProgramTitleCard';
import { useQuery } from 'react-query';
import {
  GET_TEACHER_ID_SESSION_FLOW,
  LIST_OF_CLASSES,
} from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import { useParams } from 'react-router-dom';
import appColors from 'version-two/theme/appColors';
import { useRef, useState } from 'react';
import { MAKE_APP_WEB_RECORDINGS_VIDEO_PLAYER } from 'version-two/navigation/routes';
import { PlayIcon } from 'version-two/assets';
import AppButton from '../Typography/AppButton';
import { formatDate, getTag } from '../CommonFunctions/CommonFunctions';
import AppLoader from '../AppLoader/AppLoader';
import TeacherClassRecordingDialog from '../TeacherClassRecordingDialog/TeacherClassRecordingDialog';

function TeacherScheduleTab({ setClassDetails }) {
  const { programSlug } = useParams();
  const [classId, setClassId] = useState(null);
  const recordingRef = useRef();
  const {
    isOpen: recordingIsOpen,
    onOpen: recordingOnOpen,
    onClose: recordingOnClose,
  } = useDisclosure();

  const tableMeta = {
    week_number: 'WEEK',
    status: 'STATUS',
    date: 'DATE',
    teacher_name: "TRAINER'S NAME",
    attendance: 'ATTENDANCE',
    file_duration: 'CLASS RECORDINGS',
  };

  const columnHeaders = Object.values(tableMeta);

  const { data: programDetail } = useQuery(
    GET_TEACHER_ID_SESSION_FLOW(programSlug),
    () => makeGetRequest(GET_TEACHER_ID_SESSION_FLOW(programSlug)),
  );

  const {
    data: classData,
    isLoading,
    refetch,
  } = useQuery(LIST_OF_CLASSES(programSlug), () =>
    makeGetRequest(LIST_OF_CLASSES(programSlug)),
  );

  const MarkDone = (id) => {
    setClassId(id);
    recordingOnOpen();
  };

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const giveValidDuration = (timing) => {
    const arr = timing.split(':');
    arr.pop();
    if (arr[0] === '0') return `00:${arr[1]}`;
    return arr.join(':');
  };

  const navigateToVideoPlayer = (id) => {
    window.open(MAKE_APP_WEB_RECORDINGS_VIDEO_PLAYER(programSlug, id));
  };

  return (
    <>
      <ProgramTitleCard
        title="Schedule"
        type={programDetail?.data.batch.identity}
      />
      <AppLoader isLoading={isLoading} height="30vh">
        {/* MOBILE VIEW */}
        <Flex
          display={isMobile ? 'flex' : 'none'}
          width="100%"
          flexDirection="column"
          mb="30px"
        >
          {classData?.data?.map((liveClassData) => {
            const isNotStartedClass =
              liveClassData?.status === 'Not yet started';
            return (
              <Flex
                key={`class-${liveClassData?.id}`}
                justifyContent="space-between"
                alignItems="flex-end"
                padding="20px 0px"
                fontSize="13px"
                fontWeight="600"
                borderBottom={`1px solid ${appColors.brandGrey[200]}`}
                opacity={isNotStartedClass ? 0.6 : 1}
              >
                <Flex
                  flexDirection="column"
                  gap={3}
                  onClick={() => {
                    if (!isNotStartedClass) {
                      setClassDetails({
                        name: liveClassData?.name,
                        id: liveClassData?.id,
                      });
                    }
                  }}
                >
                  <Text fontSize="14px" fontWeight="700">
                    {liveClassData?.name ?? '-'}
                  </Text>
                  {getTag(liveClassData?.status)}
                  <Text>
                    {liveClassData?.date} - {liveClassData?.trainer_name}
                  </Text>
                  <Text>Attendance - {liveClassData?.attendance}</Text>
                </Flex>
                <Flex flexDirection="column" alignItems="flex-end" gap={3}>
                  <Text mr="10px">{liveClassData?.link ?? '-'}</Text>
                  <AppButton
                    title="Mark as Done"
                    variant={
                      liveClassData?.status === 'Completed'
                        ? 'outline'
                        : 'solid'
                    }
                    disabled={
                      !liveClassData?.attendance ||
                      liveClassData?.status !== 'Upcoming'
                    }
                    customStyles={{
                      m: '0px',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (liveClassData?.status === 'Upcoming') {
                        MarkDone(liveClassData?.id);
                      }
                    }}
                  />
                </Flex>
              </Flex>
            );
          })}
        </Flex>

        <TeacherClassRecordingDialog
          isOpen={recordingIsOpen}
          onClose={recordingOnClose}
          ClassID={classId}
          BatchClassRefetch={refetch}
          leastDestructiveRef={recordingRef}
        />

        {/* DESKTOP VIEW */}
        <Flex
          display={!isMobile ? 'flex' : 'none'}
          width="100%"
          flexDirection="column"
          mb="30px"
        >
          <TableContainer>
            <Table colorScheme="facebook">
              <Thead>
                <Tr>
                  {columnHeaders.map((item) => (
                    <Th key={`schedule-${item}`} padding="10px">
                      <Text
                        color={appColors.brandGrey[900]}
                        fontSize="12px"
                        fontWeight={700}
                      >
                        {item}
                      </Text>
                    </Th>
                  ))}
                  <Th padding="10px" />
                </Tr>
              </Thead>
              <Tbody>
                {classData?.data?.map((liveClassData) => {
                  const isNotStartedClass =
                    liveClassData?.status === 'Not yet started';
                  return (
                    <Tr
                      key={`class-${liveClassData?.id}`}
                      _hover={{
                        bg: !isNotStartedClass && appColors.brandGrey[150],
                      }}
                      opacity={isNotStartedClass ? 0.6 : 1}
                      cursor={!isNotStartedClass && 'pointer'}
                      onClick={() => {
                        if (!isNotStartedClass) {
                          setClassDetails({
                            name: liveClassData?.name,
                            id: liveClassData?.id,
                          });
                        }
                      }}
                    >
                      <Td
                        padding="10px"
                        cursor={!isNotStartedClass && 'pointer'}
                        onClick={() => {
                          if (!isNotStartedClass) {
                            setClassDetails({
                              name: liveClassData?.name,
                              id: liveClassData?.id,
                            });
                          }
                        }}
                      >
                        <Text>{liveClassData?.name ?? '-'}</Text>
                      </Td>
                      <Td padding="10px">{getTag(liveClassData?.status)}</Td>
                      <Td padding="10px">
                        <Text>{formatDate(liveClassData?.date)}</Text>
                      </Td>
                      <Td padding="10px">
                        <Text>{liveClassData?.trainer_name ?? '-'}</Text>
                      </Td>
                      <Td padding="10px">
                        <Text>{liveClassData?.attendance ?? '-'}</Text>
                      </Td>
                      <Td padding="10px">
                        <Flex
                          flexBasis={{ base: '30%', sm: '20%' }}
                          style={{
                            gap: '5px',
                            cursor: liveClassData.file_duration && 'pointer',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (liveClassData.file) {
                              navigateToVideoPlayer(liveClassData.id);
                            }
                          }}
                        >
                          <Text>
                            {giveValidDuration(liveClassData.file_duration)}
                          </Text>
                          <Image src={PlayIcon} alt="" />
                        </Flex>
                      </Td>
                      <Td padding="10px">
                        <AppButton
                          title="Mark as Done"
                          variant={
                            liveClassData?.status === 'Completed'
                              ? 'outline'
                              : 'solid'
                          }
                          disabled={
                            !liveClassData?.attendance ||
                            liveClassData?.status !== 'Upcoming' ||
                            (liveClassData?.link === 'Triggered' &&
                              !liveClassData?.file)
                          }
                          customStyles={{
                            m: '0px',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (liveClassData?.status === 'Upcoming') {
                              MarkDone(liveClassData?.id);
                            }
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          {programDetail?.data?.schedules?.length === 0 && !isLoading && (
            <Flex width="100%" justifyContent="center">
              <Text padding="15px">No Data Available</Text>
            </Flex>
          )}
        </Flex>
      </AppLoader>
    </>
  );
}

export default TeacherScheduleTab;
