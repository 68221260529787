/* eslint-disable no-nested-ternary */
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
} from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { IoEye, IoEyeOff } from 'react-icons/io5';

const AppInputV3 = forwardRef(
  (
    {
      width,
      bg,
      label,
      subLabel,
      name,
      id,
      placeholder,
      type,
      value,
      defaultValue,
      onChange,
      accept,
      min,
      isRequired,
      isError,
      readonly,
      isLoading,
      onKeyDownCapture,
      customStyles,
      leftAddon,
      leftElm,
      onFocus,
      onBlur,
      onInputChange,
      // ref,
      hidden = false,
      ...restProps
    },
    ref,
  ) => {
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    return (
      <FormControl className="app-input" width={width} isInvalid={isError}>
        {label && (
          <FormLabel
            htmlFor={name}
            fontSize="14px"
            fontWeight="500"
            cursor="pointer"
          >
            {label} {isRequired && <span style={{ color: 'red' }}>*</span>}{' '}
            {subLabel && <span style={{ color: '#A7ABB1' }}> {subLabel} </span>}
          </FormLabel>
        )}
        <InputGroup>
          {leftAddon && (
            <InputLeftAddon bg={bg} className="left-addon">
              {leftAddon}
            </InputLeftAddon>
          )}
          {leftElm && (
            <InputLeftAddon bg={bg} borderRight="none" className="left-addon">
              {leftElm}
            </InputLeftAddon>
          )}
          <Input
            min={min}
            readOnly={readonly}
            id={name}
            ref={ref}
            placeholder={placeholder}
            name={name}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            accept={accept}
            onFocus={onFocus}
            onBlur={onBlur}
            onInput={onInputChange}
            onWheelCapture={(event) => event.target.blur()}
            type={type !== 'password' ? type : show ? 'text' : 'password'}
            required={isRequired}
            errorBorderColor="red.300"
            {...customStyles}
            {...restProps}
            bg={bg}
            onKeyDownCapture={onKeyDownCapture}
            hidden={hidden}
            className={
              (leftAddon && 'right-input') || (leftElm && 'remove-right-border')
            }
          />
          {type === 'password' && (
            <InputRightElement>
              <Flex cursor="pointer" mt="-5px" onClick={handleClick}>
                {show ? <IoEyeOff /> : <IoEye />}
              </Flex>
            </InputRightElement>
          )}
        </InputGroup>
        {isError && (
          <FormErrorMessage mt="3px" fontSize="11px" pos="absolute">
            {isError?.message}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  },
);

export default AppInputV3;
