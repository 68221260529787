export const APP_TEACHER = 'teacher';
export const APP_STUDENT = 'student';

export const FROM_WEB_VIEW = 'from_web_view';
export const IS_IOS_DEVICE = 'is_ios_device';

export const COMET_CHAT_APP_ID = process.env.REACT_APP_COMET_CHAT_APP_ID;
export const COMET_CHAT_APP_REGION = process.env.REACT_APP_APP_REGION;
export const COMET_CHAT_AUTH_KEY = process.env.REACT_APP_COMET_CHAT_AUTH_KEY;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const REACT_NATIVE_MESSAGES = {
  GO_BACK: 'go-back',
  SWITCH_TO_PORTRAIT: 'switch-to-portrait',
  SWITCH_TO_LANDSCAPE: 'switch-to-landscape',
};
