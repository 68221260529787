/* eslint-disable radix */
import { Flex } from '@chakra-ui/react';

export const toastFunction = (toast, message, status, sec = 3000) => {
  toast({
    description: message,
    status,
    duration: sec,
    isClosable: true,
  });
};

export const getTag = (tag) => {
  const colors = {
    bg: '',
    color: '',
  };
  if (tag === 'Completed') {
    colors.bg = '#2FB3441A';
    colors.color = '#009432';
  }
  if (tag === 'Upcoming') {
    colors.bg = '#206BC41A';
    colors.color = '#206BC4';
  }
  if (tag === 'Absent') {
    colors.bg = '#E876631A';
    colors.color = '#E87663';
  }
  if (tag === 'Cancelled') {
    colors.bg = '#DA37301A';
    colors.color = '#DA3730';
  }
  if (tag === 'Not yet started') {
    colors.bg = '#FFBB001A';
    colors.color = '#FFBB00';
  }
  return (
    <Flex
      flexShrink="0"
      w="max-content"
      justifyContent="center"
      padding="4px 16px"
      fontSize="16px"
      fontWeight="600"
      // bg={colors.bg}
      border={`1px solid ${colors.color}`}
      color={colors.color}
      borderRadius="3px"
      gap="5px"
      alignItems="center"
    >
      {tag}
    </Flex>
  );
};

export const getStatusColor = (status) => {
  let color = '';
  if (status === 'Success' || status === 'Done') {
    color = '#009432';
  }
  if (status === 'Pending') {
    color = '#F60000';
  }
  return color;
};

export const formatDate = (dateString) => {
  if (!dateString) return '-';
  const parts = dateString.split('-');
  const year = parseInt(parts[0]);
  const monthIndex = parseInt(parts[1]) - 1;
  const day = parseInt(parts[2]);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const formattedDate = `${months[monthIndex]} ${day}, ${year}`;

  return formattedDate;
};

export const extractTime = (time) => {
  const timePart = time?.split('T')[1];
  const newTime = timePart?.split('.')[0];

  return newTime;
};
