import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Textarea,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppText from 'version-two/components/Typography/AppText';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import AppButton from 'version-two/components/Typography/AppButton';
import appColors from 'version-two/theme/appColors';
import { fontWeights } from 'version-two/theme/fonts';
import { appBorderRadius } from 'version-two/theme/sizes';
import { useState } from 'react';

function TeacherAddNoteDialog({
  isOpen,
  onClose,
  leastDestructiveRef,
  onSubmit,
}) {
  const [note, setNote] = useState('');

  const handleTextareaChange = (event) => {
    setNote(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(note);
  };

  const handleDialogClose = () => {
    onClose();
  };

  const alertDialogSize = useBreakpointValue({ base: 'full', sm: 'sm' });
  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={isOpen}
      onClose={handleDialogClose}
      isCentered
      size={alertDialogSize}
    >
      <AlertDialogOverlay />

      <AlertDialogContent
        style={{
          borderRadius: appBorderRadius.md,
          paddingTop: 10,
        }}
        variant="uploadDialog"
      >
        <AlertDialogHeader>
          <AppText
            title="New Note"
            size="md"
            customStyles={{
              fontWeight: fontWeights.bold,
            }}
          />
        </AlertDialogHeader>
        <AlertDialogCloseButton
          color={appColors.brandGrey['300']}
          mt={3}
          mr={2}
        />

        <AlertDialogBody>
          <AppText title="A short note" size="xs" />
          <Textarea
            name="note"
            onChange={handleTextareaChange}
            customStyles={{
              mb: 7,
            }}
          />
        </AlertDialogBody>
        <AppFlex
          variant="alertDialogFooter"
          customStyles={{ justifyContent: 'flex-end' }}
        >
          <AppButton
            title="Add"
            variant="solid"
            onClick={handleSubmit}
            customStyles={{
              marginTop: 0,
            }}
          />
        </AppFlex>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export default TeacherAddNoteDialog;
