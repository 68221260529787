/* eslint-disable radix */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import { RRule } from 'rrule';

function renderEventContent(data) {
  const value = data?.event?.extendedProps?.details;
  const color = data?.event?.backgroundColor;
  const title = value?.batch_name ?? value?.slot;
  const noOfStudents = value?.occupied_seats
    ? `- ${value?.occupied_seats}/ ${value.no_of_seats}`
    : null;
  const course = value?.course_grade ?? '-';
  const trainerName =
    value?.slot && value.trainer_name ? `${value.trainer_name} - ` : '';

  return (
    <Flex
      width="100%"
      flexDirection="column"
      style={{ backgroundColor: color }}
      padding="5px"
      whiteSpace="normal"
      color="white"
      borderRadius="3px"
      // gap={2}
      // minHeight={"60px"}
      fontSize="10px"
      border={`1px solid ${color}`}
      className="event-box"
    >
      <Box>
        {trainerName} {title} {noOfStudents}
      </Box>
      <Box className="course-name">{course}</Box>
    </Flex>
  );
}

function AppCalendar({ state, calendarRef, schedulerData, filterBatchStatus }) {
  schedulerData?.batches.forEach((batch) => {
    schedulerData?.slots.forEach((slot) => {
      if (
        batch.trainer_id === slot.trainer_id &&
        batch.day === slot.day &&
        batch.time === slot.time
      ) {
        const endDate = new Date(batch.end_date);
        endDate.setDate(endDate.getDate() + 7);
        slot.start_date = batch.end_date ? endDate.toISOString() : null;
        slot.batch_status = batch.status;
      }
    });
  });

  const [combinedArray, setCombinedArray] = useState([
    ...schedulerData?.batches,
    ...schedulerData?.slots,
  ]);

  useEffect(() => {
    if (filterBatchStatus?.length > 0) {
      const batchStatus = [filterBatchStatus] || [];
      const filteredClasses = { batches: [], slots: [] };

      if (
        batchStatus.includes('Not yet started') ||
        batchStatus.includes('Ongoing') ||
        batchStatus.includes('Upcoming') ||
        batchStatus.includes('Completed')
      ) {
        filteredClasses.batches = schedulerData?.batches;
      }

      if (batchStatus.includes('Available slots')) {
        filteredClasses.slots = schedulerData?.slots;
      }

      setCombinedArray([
        ...filteredClasses?.batches,
        ...filteredClasses?.slots,
      ]);
    }
  }, [filterBatchStatus]);

  const dayToRRuleByWeekday = {
    Sunday: RRule.SU,
    Monday: RRule.MO,
    Tuesday: RRule.TU,
    Wednesday: RRule.WE,
    Thursday: RRule.TH,
    Friday: RRule.FR,
    Saturday: RRule.SA,
  };

  const INITIAL_EVENTS = combinedArray
    ?.filter((item) => !(item?.batch_status && !item?.start_date))
    .map((event, index) => {
      const { start_date, end_date, color_code, batch_name, slot, day, time } =
        event;

      const [startTime, endTime] = time.split(' - ');
      const [startHour, startMinute] = startTime
        .match(/(\d+):(\d+)/)
        .slice(1, 3);
      const [endHour, endMinute] = endTime.match(/(\d+):(\d+)/).slice(1, 3);

      const startHour24 =
        (parseInt(startHour) % 12) + (startTime.includes('PM') ? 12 : 0);
      const endHour24 =
        (parseInt(endHour) % 12) + (endTime.includes('PM') ? 12 : 0);

      const formatDate = (date) => {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const dayy = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');
        return `${year}-${month}-${dayy}T${hours}:${minutes}:${seconds}.${milliseconds}`;
      };

      //   const formattedStartTime = `${startHour24
      //     .toString()
      //     .padStart(2, '0')}:${startMinute}`;
      //   const formattedEndTime = `${endHour24
      //     .toString()
      //     .padStart(2, '0')}:${endMinute}`;

      const defaultStartDate = new Date();
      defaultStartDate.setHours(startHour24, startMinute);

      const startDate = start_date ? new Date(start_date) : new Date();
      startDate.setUTCHours(startHour24, parseInt(startMinute), 0, 0);
      const endDate = end_date ? new Date(end_date) : new Date('3000-12-30'); // Hardcoding end date so the event is displayed in week and day view
      endDate.setUTCHours(endHour24, parseInt(endMinute), 0, 0);

      let rrule;
      if (end_date && start_date) {
        rrule = {
          freq: 'weekly',
          byweekday: [dayToRRuleByWeekday[day]],
          dtstart: formatDate(startDate),
          until: formatDate(endDate),
        };
      } else {
        rrule = {
          freq: 'weekly',
          byweekday: [dayToRRuleByWeekday[day]],
          dtstart: formatDate(startDate),
        };
      }

      return {
        id: index,
        groupId: index,
        details: event,
        title: batch_name || slot,
        start: formatDate(startDate),
        end: formatDate(endDate),
        // startTime: formattedStartTime,
        // endTime: formattedEndTime,
        backgroundColor: color_code,
        // color: color_code,
        rrule,
      };
    });

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(state?.currentDate);
    }
  }, [state?.currentDate]);

  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <>
      <FullCalendar
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          rrulePlugin,
        ]}
        initialView="dayGridMonth"
        views={['dayGridMonth', 'timeGridWeek', 'timeGridDay']}
        headerToolbar={{
          left: '',
          center: '',
          right: '',
        }}
        // headerToolbar={{
        //     start: "prev title next",
        //     end: "timeGridDay timeGridWeek dayGridMonth",
        //   }}
        ref={calendarRef}
        // editable={true}
        // selectable={true}
        selectMirror
        dayMaxEvents
        dayMaxEventRows
        allDaySlot={false}
        // initialEvents={INITIAL_EVENTS}
        events={INITIAL_EVENTS}
        eventContent={renderEventContent}
        eventMaxStack={1}
        stickyHeaderDates
      />
      <style>{`
        .fc .fc-daygrid-event {
          font-size: 10px;
          padding: 0px;
          white-space: normal;
          color: white;
        }
        .fc .fc-timegrid-slot {
          height: ${
            isMobile && state?.currentViewName !== 'timeGridDay'
              ? '35px'
              : '30px'
          };
        }
        .fc .fc-timegrid-slot-minor {
          border: none;
        }
        .fc .fc-daygrid-day-number {
          z-index: none;
        }
        .fc-daygrid-event-harness {
          margin-top: 0 !important;
        }
        .fc .fc-scrollgrid-sync-inner {
          padding: ${isMobile ? '5px !important' : '10px 20px !important'}
        }
        .fc .fc-event-start{
          font-size: 10px;
          padding: 0px;
          white-space: normal;
          color: white;
        }
        .fc .fc-toolbar.fc-header-toolbar {
          display: none;
        }
        .fc-v-event {
          border: none;
        }
        .fc-toolbar-chunk {
          display: flex;
          align-items: center;
        }
        .fc-view-harness {
          height: ${isMobile ? '600px !important' : 'auto'};
          background-color: white;
          font-size: ${isMobile ? '12px' : '16px'}
        }
        .fc-next-button, .fc-prev-button {
          background-color: transparent !important;
          border: none !important;
          color: black !important;
        }
        .fc-icon-chevron-right, .fc-icon-chevron-left {
          font-size: 40px !important;
        }
        .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky>* {
          top: 0px;
        }
        .event-box {
          font-size: ${isMobile ? '6px' : '10px'};
          padding: ${isMobile ? '2px' : '5px'};
        }
        .event-box .course-name {
          display: ${isMobile ? 'none' : 'block'}
        }
        `}</style>
    </>
  );
}

export default AppCalendar;
