import React, { forwardRef } from 'react';
import { Select } from 'chakra-react-select';
import { Controller } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';

const AppSelectV3 = forwardRef(
  ({
    options = [],
    dontAlterOptions,
    value,
    bg,
    width,
    size,
    label,
    placeholder,
    control,
    name,
    mt,
    isError = false,
    searchable = false,
    isClearable,
    onChange,
    onInputChange,
    onFocus,
    defaultValue,
    isMulti,
    isRequired = false,
    formMessage = isError?.message,
    isLoading = false,
    readonly = false,
    ...restProps
  }) => {
    const getOptions = () => {
      if (dontAlterOptions) return options;

      return options?.map((m) => ({
        label: m?.identity ?? m?.email,
        value: m?.id,
        ...m,
      }));
    };

    const chakraStyles = {
      placeholder: (provided) => ({
        ...provided,
        fontSize: '14px',
      }),
      option: (provided) => ({ ...provided, fontSize: '14px' }),
      control: (provided) => ({
        ...provided,
        fontSize: '14px',
        height: '40px',
        minHeight: 'auto',
      }),
      singleValue: (provided) => ({
        ...provided,
        fontSize: '14px',
      }),
      multiValue: (provided) => ({
        ...provided,
        flexShrink: '0',
      }),
      container: (provided) => ({
        ...provided,
        zIndex: 999,
      }),
      valueContainer: (provided) => ({
        ...provided,
        flexWrap: isMulti ? 'nowrap' : 'wrap',
        overflowX: isMulti ? 'auto' : 'hidden',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 99999,
      }),
    };

    if (readonly && isMulti) {
      chakraStyles.multiValueRemove = (provided) => ({
        ...provided,
        display: 'none',
      });
    }

    return (
      <Controller
        name={name}
        control={control}
        {...restProps}
        render={({ field }) => (
          <FormControl width={width || 'auto'} isInvalid={isError}>
            {label && (
              <FormLabel fontSize="14px" fontWeight="500">
                {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
              </FormLabel>
            )}
            <Box bg={bg} borderRadius="0.375rem" mt={mt}>
              <Select
                useBasicStyles
                classNamePrefix="app-select-field"
                // defaultValue={defaultValue}
                // defaultInputValue={value}
                value={value}
                isRequired={isRequired}
                {...field}
                {...restProps}
                options={getOptions()}
                placeholder={placeholder ?? 'Select'}
                errorBorderColor="red.500"
                isReadOnly={readonly}
                isMulti={isMulti}
                isSearchable={searchable}
                isLoading={isLoading}
                onInputChange={onInputChange}
                isClearable={(!readonly && isMulti) || isClearable}
                menuPlacement="auto"
                menuPosition="fixed"
                chakraStyles={chakraStyles}
                selectedOptionColorScheme="blue"
                hideSelectedOptions={false}
                closeMenuOnSelect={!isMulti}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                // onMenuOpen={(e) => readonly && e.preventDefault()}
              />
            </Box>
            {formMessage && (
              <FormErrorMessage mt="1px" fontSize="11px" pos="absolute">
                {formMessage}
              </FormErrorMessage>
            )}
          </FormControl>
        )}
      />
    );
  },
);

export default AppSelectV3;
