import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { CometChatNotifications } from '@cometchat/chat-sdk-javascript';
import { firebaseConfig } from 'version-two/utils/constants';

export default async function firebaseInitialize() {
  const app = initializeApp(firebaseConfig); // Initialize Firebase App
  const messaging = getMessaging(app); // Initialize Firebase Messaging

  // Check if the token is already saved in localStorage
  const savedToken = localStorage.getItem('fcmToken');

  if (savedToken) {
    // console.log('Using saved token');
    // Register the token with CometChat
    CometChatNotifications.registerPushToken(
      savedToken,
      CometChatNotifications.PushPlatforms.FCM_WEB,
      'fcm-provider-1',
    )
      .then(() => {
        // console.log('Token registration successful', payload);
      })
      .catch(() => {
        localStorage.removeItem('fcmToken');
        // console.log('Token registration failed', err);
      });
  } else {
    // If no token is saved, generate a new token
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    })
      .then((currentToken) => {
        if (currentToken) {
          // console.log('Generated new token');

          // Save the new token in localStorage
          localStorage.setItem('fcmToken', currentToken);

          // Register the new token with CometChat
          CometChatNotifications.registerPushToken(
            currentToken,
            CometChatNotifications.PushPlatforms.FCM_WEB,
            'fcm-provider-1',
          )
            .then(() => {
              // console.log('Token registration successful', payload);
            })
            .catch(() => {
              localStorage.removeItem('fcmToken');
              // console.log('Token registration failed', err);
            });
        } else {
          // console.log(
          //   'No registration token available. Request permission to generate one.',
          // );
        }
      })
      .catch(() => {
        // console.log('An error occurred while retrieving token', err);
      });
  }

  // Listen for incoming messages
  onMessage(messaging, () => {
    // console.log('Got message', payload);
  });
}
