/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import AppCalendar from 'version-two/components/AppCalendar/AppCalendar';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import useSchedulerHandler from 'version-two/hooks/useSchedulerHandler';
import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
import {
  Box,
  Checkbox,
  Flex,
  Radio,
  RadioGroup,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import appColors from 'version-two/theme/appColors';
import { IoIosArrowBack, IoIosArrowForward, IoMdClose } from 'react-icons/io';
import AppSelectV3 from 'version-two/components/AppSelect/AppSelectV3';
import { useNavigate } from 'react-router-dom';
import AppButton from 'version-two/components/Typography/AppButton';
import { TEACHER_DASHBOARD_MANAGE_AVAILABILITY } from 'version-two/navigation/routes';
import {
  TEACHER_VIEW_BATCH_LIST_META,
  VIEW_TRAINER_CALENDAR,
} from 'version-two/api/urls';
import { useMutation, useQuery } from 'react-query';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import useAppStore from 'version-two/store';
import { FiFilter } from 'react-icons/fi';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { CalendarIcon } from '@chakra-ui/icons';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';

function TeacherAvailability() {
  const navigate = useNavigate();
  const [classes, setClasses] = useState({
    batches: [],
    slots: [],
  });
  const { state, setState } = useSchedulerHandler({
    schedulerData: classes,
  });
  const [selectedCalendarView, setSelectedCalendarView] =
    useState('dayGridMonth');
  const calendarRef = useRef(null);
  const [headerTitle, setHeaderTitle] = useState('');
  const trainerId = useAppStore.getState().trainer_id;
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const [isReset, setIsReset] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showCalendarViews, setShowCalendarViews] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedBatchesID, setSelectedBatchesID] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [selectedBatchStatus, setSelectedBatchStatus] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const { register, watch, control, setValue } = useForm();

  const { data: BatchData } = useQuery(
    [TEACHER_VIEW_BATCH_LIST_META(trainerId)],
    () => makeGetRequest(TEACHER_VIEW_BATCH_LIST_META(trainerId)),
  );

  const {
    data: calendarData,
    isLoading: calendarIsLoading,
    mutate: calendarMutate,
  } = useMutation(
    (body) => makePostRequest(VIEW_TRAINER_CALENDAR(trainerId), body),
    {
      onSuccess: (res) => {
        setClasses({ batches: res.batches || [], slots: res.slots || [] });

        if (
          watch('course_and_grade')?.length > 0 ||
          watch('batches')?.length > 0 ||
          selectedBatches?.length > 0 ||
          selectedCourses?.length > 0
        ) {
          setClasses({ batches: res.batches || [], slots: [] });
        }

        setIsReset(false);
      },
    },
  );

  const updateHeaderTitle = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setHeaderTitle(calendarApi.view.title);
    }
  };

  function JoinInput(input) {
    const values = input?.map((item) => item.label) || [];
    return values || undefined;
  }

  const getCalendarData = () => {
    const batchStatusValue = watch('batch_status')?.value;
    const statusArray = batchStatusValue ? [batchStatusValue] : [];

    const batchNames = isMobile ? selectedBatches : JoinInput(watch('batches'));
    const courseGrades = isMobile
      ? selectedCourses
      : JoinInput(watch('course_and_grade'));
    const filteredStatus = isMobile
      ? selectedBatchStatus
        ? [selectedBatchStatus]
        : []
      : statusArray.filter((status) => status && status !== 'Available slots');

    calendarMutate({
      batch_name: batchNames,
      status: filteredStatus,
      course_grade: courseGrades,
    });
  };

  useEffect(() => {
    if (trainerId) {
      getCalendarData();
    }
  }, [
    trainerId,
    watch('batches'),
    watch('course_and_grade'),
    watch('batch_status'),
  ]);

  const handleReset = () => {
    setValue('batches', null);
    setValue('batch_status', null);
    setValue('course_and_grade', null);
    setSelectedBatchesID([]);
    setSelectedBatches([]);
    setSelectedCourses([]);
    setSelectedBatchStatus(null);
    setIsReset(true);
  };

  const handleChange = (selectValue) => {
    setState({ ...state, currentViewName: selectValue });
    setSelectedCalendarView(selectValue);
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      calendarApi.changeView(selectValue, state?.currentDate);
      setHeaderTitle(calendarApi.view.title);
    }
  };

  const handleNext = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.next();
      setHeaderTitle(calendarApi.view.title);
    }
  };

  const handlePrev = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
      setHeaderTitle(calendarApi.view.title);
    }
  };

  useEffect(() => {
    updateHeaderTitle();
  }, [state.currentDate, classes]);

  useEffect(() => {
    if (isReset) {
      getCalendarData();
    }
  }, [isReset]);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  useEffect(() => {
    if (showFilter) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showFilter]);

  const handleCheckboxChange = (type, id, identity, isChecked) => {
    if (type === 'batches') {
      setSelectedBatches((prev) =>
        isChecked
          ? [...prev, identity]
          : prev.filter((batch) => batch !== identity),
      );
      setSelectedBatchesID((prev) =>
        isChecked ? [...prev, id] : prev.filter((batch) => batch !== id),
      );
    } else if (type === 'courses') {
      setSelectedCourses((prev) =>
        isChecked ? [...prev, id] : prev.filter((course) => course !== id),
      );
    }
  };

  const isChecked = (type, id) => {
    if (type === 'batches') {
      return selectedBatchesID.includes(id);
    }
    if (type === 'courses') {
      return selectedCourses.includes(id);
    }
    return false;
  };

  return (
    <TeacherDashboardLayout maxWidth={false}>
      <AppLoader isLoading={calendarIsLoading}>
        <Flex
          width="100%"
          flexDirection="column"
          alignItems="center"
          padding={isMobile ? '0' : '20px'}
          gap={5}
        >
          {/* CALENDAR VIEW  */}
          <Flex
            display={showCalendarViews ? 'flex' : 'none'}
            position="fixed"
            top={0}
            right={0}
            width="100vw"
            bg="white"
            zIndex={999}
            flexDirection="column"
            style={{ boxShadow: '-3px 0px 20px rgba(0, 0, 0, 0.16)' }}
          >
            <Flex
              padding="20px 30px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text
                fontSize="20px"
                fontWeight="700"
                color={appColors.brandSecondary[500]}
              >
                Calendar View
              </Text>
              <Box
                fontSize="20px"
                fontWeight="600"
                onClick={() => setShowCalendarViews(false)}
              >
                <IoMdClose />
              </Box>
            </Flex>
            <Flex
              width="100%"
              padding="10px 30px"
              justifyContent="space-between"
              alignItems="center"
              cursor="pointer"
              bg={
                selectedCalendarView === 'timeGridDay' &&
                appColors.brandPrimary['300']
              }
              color={
                selectedCalendarView === 'timeGridDay' &&
                appColors.brandWhite[900]
              }
              borderBottom={`1px solid ${appColors.brandGrey[600]}`}
              onClick={() => handleChange('timeGridDay')}
            >
              <Text fontSize="13px" fontWeight="600">
                Day View
              </Text>
            </Flex>
            <Flex
              width="100%"
              padding="10px 30px"
              justifyContent="space-between"
              alignItems="center"
              cursor="pointer"
              bg={
                selectedCalendarView === 'timeGridWeek' &&
                appColors.brandPrimary['300']
              }
              color={
                selectedCalendarView === 'timeGridWeek' &&
                appColors.brandWhite[900]
              }
              borderBottom={`1px solid ${appColors.brandGrey[600]}`}
              onClick={() => handleChange('timeGridWeek')}
            >
              <Text fontSize="13px" fontWeight="600">
                Week View
              </Text>
            </Flex>
            <Flex
              width="100%"
              padding="10px 30px"
              justifyContent="space-between"
              alignItems="center"
              cursor="pointer"
              bg={
                selectedCalendarView === 'dayGridMonth' &&
                appColors.brandPrimary['300']
              }
              color={
                selectedCalendarView === 'dayGridMonth' &&
                appColors.brandWhite[900]
              }
              borderBottom={`1px solid ${appColors.brandGrey[600]}`}
              onClick={() => handleChange('dayGridMonth')}
            >
              <Text fontSize="13px" fontWeight="600">
                Month View
              </Text>
            </Flex>
          </Flex>

          {/* FILTER FOR MOBILE VIEW */}
          <Flex
            display={showFilter ? 'flex' : 'none'}
            position="fixed"
            top={0}
            right={0}
            width="300px"
            minHeight="100vh"
            bg="white"
            zIndex={999}
            flexDirection="column"
            justifyContent="space-between"
            style={{ boxShadow: '-3px 0px 20px rgba(0, 0, 0, 0.16)' }}
          >
            <Flex flexDirection="column">
              <Flex
                padding="20px 30px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  color={appColors.brandSecondary[500]}
                >
                  Filters
                </Text>
                <Box
                  fontSize="20px"
                  fontWeight="600"
                  onClick={() => setShowFilter(false)}
                >
                  <IoMdClose />
                </Box>
              </Flex>
              <Flex flexDirection="column" maxHeight="80vh" overflowY="auto">
                <Flex
                  width="100%"
                  flexDirection="column"
                  borderBottom={`1px solid ${appColors.brandGrey[600]}`}
                >
                  <Flex
                    width="100%"
                    padding="20px 30px"
                    justifyContent="space-between"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => toggleDropdown('batches')}
                  >
                    <Text fontSize="13px" fontWeight="600">
                      Batches
                    </Text>
                    <Box>
                      <MdKeyboardArrowDown />
                    </Box>
                  </Flex>
                  {openDropdown === 'batches' && (
                    <Flex
                      width="100%"
                      padding="0px 30px 20px 40px"
                      flexDirection="column"
                      gap={3}
                    >
                      {BatchData?.data?.batch_names.map((batch) => (
                        <Flex key={batch.id} gap={2} alignItems="center">
                          <Checkbox
                            isChecked={isChecked('batches', batch.id)}
                            onChange={(e) =>
                              handleCheckboxChange(
                                'batches',
                                batch.id,
                                batch.identity,
                                e.target.checked,
                              )
                            }
                          />
                          <Text
                            fontSize={isMobile ? '13px' : '16px'}
                            color={appColors.brandGrey[500]}
                          >
                            {batch.identity}
                          </Text>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </Flex>
                <Flex
                  width="100%"
                  flexDirection="column"
                  borderBottom={`1px solid ${appColors.brandGrey[600]}`}
                >
                  <Flex
                    width="100%"
                    padding="20px 30px"
                    justifyContent="space-between"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => toggleDropdown('batch_status')}
                  >
                    <Text fontSize="13px" fontWeight="600">
                      Batch Status
                    </Text>
                    <Box>
                      <MdKeyboardArrowDown />
                    </Box>
                  </Flex>
                  {openDropdown === 'batch_status' && (
                    <Flex
                      width="100%"
                      padding="0px 30px 20px 40px"
                      flexDirection="column"
                      gap={3}
                    >
                      <RadioGroup
                        onChange={setSelectedBatchStatus}
                        value={selectedBatchStatus}
                        display="flex"
                        flexDirection="column"
                        gap={2}
                      >
                        {BatchData?.data?.batch_status.map((batch) => (
                          <Flex key={batch.id} gap={2} alignItems="center">
                            <Radio value={batch.id} />
                            <Text
                              fontSize={isMobile ? '13px' : '16px'}
                              color={appColors.brandGrey[500]}
                            >
                              {batch.identity}
                            </Text>
                          </Flex>
                        ))}
                      </RadioGroup>
                    </Flex>
                  )}
                </Flex>
                <Flex
                  width="100%"
                  flexDirection="column"
                  borderBottom={`1px solid ${appColors.brandGrey[600]}`}
                >
                  <Flex
                    width="100%"
                    padding="20px 30px"
                    justifyContent="space-between"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => toggleDropdown('course')}
                  >
                    <Text fontSize="13px" fontWeight="600">
                      Course & Grade
                    </Text>
                    <Box>
                      <MdKeyboardArrowDown />
                    </Box>
                  </Flex>
                  {openDropdown === 'course' && (
                    <Flex
                      width="100%"
                      padding="0px 30px 20px 40px"
                      flexDirection="column"
                      gap={3}
                    >
                      {BatchData?.data?.course_grade.map((batch) => (
                        <Flex key={batch.id} gap={2} alignItems="center">
                          <Checkbox
                            isChecked={isChecked('courses', batch.id)}
                            onChange={(e) =>
                              handleCheckboxChange(
                                'courses',
                                batch.id,
                                batch.identity,
                                e.target.checked,
                              )
                            }
                          />
                          <Text
                            fontSize={isMobile ? '13px' : '16px'}
                            color={appColors.brandGrey[500]}
                          >
                            {batch.identity}
                          </Text>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Flex
              width="100%"
              padding="10px 20px"
              gap={5}
              borderTop={`1px solid ${appColors.brandGrey[600]}`}
            >
              <Flex
                width={isMobile ? '50%' : 'auto'}
                alignItems="center"
                justifyContent="center"
                onClick={() => {
                  handleReset();
                }}
              >
                <Text
                  color={appColors.brandSecondary[500]}
                  cursor="pointer"
                  fontWeight={600}
                  fontSize={isMobile ? '13px' : '16px'}
                >
                  Reset
                </Text>
              </Flex>
              <AppButton
                title="Apply"
                variant="solid"
                customStyles={{
                  m: '0',
                  width: isMobile ? '50%' : 'auto',
                  fontSize: isMobile ? '13px' : '16px',
                }}
                onClick={() => {
                  getCalendarData();
                  setShowFilter(false);
                }}
              />
            </Flex>
          </Flex>

          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Text fontSize={isMobile ? '16px' : '33px'} fontWeight={700}>
              Yours Classes & Availability
            </Text>
            <AppButton
              title="Manage Availability"
              variant="solid"
              customStyles={{ m: 0, fontSize: isMobile ? '13px' : '16px' }}
              onClick={() => navigate(TEACHER_DASHBOARD_MANAGE_AVAILABILITY)}
            />
          </Flex>
          <Flex
            width={isMobile ? '100%' : '80%'}
            flexDirection="column"
            padding={isMobile ? '0' : '20px'}
            gap={5}
          >
            <Flex justifyContent="space-between">
              <Flex alignItems="center" gap="10px">
                <Box
                  fontSize={isMobile ? '20px' : '30px'}
                  cursor="pointer"
                  onClick={handlePrev}
                >
                  <IoIosArrowBack />
                </Box>
                <Flex
                  width={isMobile ? '150px' : '300px'}
                  justifyContent="center"
                >
                  <Text fontSize={isMobile ? '16px' : '24px'}>
                    {headerTitle}
                  </Text>
                </Flex>
                <Box
                  fontSize={isMobile ? '20px' : '30px'}
                  cursor="pointer"
                  onClick={handleNext}
                >
                  <IoIosArrowForward />
                </Box>
              </Flex>
              <Flex
                alignItems="center"
                gap={2}
                display={isMobile ? 'flex' : 'none'}
              >
                <Flex
                  boxSize="35px"
                  border={`1px solid ${appColors.brandGrey[600]}`}
                  borderRadius="4px"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => setShowCalendarViews(true)}
                >
                  <CalendarIcon />
                </Flex>
                <Flex
                  boxSize="35px"
                  border={`1px solid ${appColors.brandGrey[600]}`}
                  borderRadius="4px"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => setShowFilter(true)}
                >
                  <FiFilter />
                </Flex>
              </Flex>
              <Flex
                borderRadius="30px"
                border={`1px solid ${appColors.brandGrey[600]}`}
                display={isMobile ? 'none' : 'flex'}
              >
                <Flex
                  padding="8px 27px"
                  borderRight={`1px solid ${appColors.brandGrey[600]}`}
                  bg={
                    selectedCalendarView === 'timeGridDay' &&
                    appColors.brandPrimary['300']
                  }
                  borderRadius="30px 0px 0px 30px"
                  color={
                    selectedCalendarView === 'timeGridDay' &&
                    appColors.brandWhite[900]
                  }
                  cursor="pointer"
                  onClick={() => handleChange('timeGridDay')}
                >
                  <Text fontWeight={600}>Day</Text>
                </Flex>
                <Flex
                  padding="8px 27px"
                  borderRight={`1px solid ${appColors.brandGrey[600]}`}
                  bg={
                    selectedCalendarView === 'timeGridWeek' &&
                    appColors.brandPrimary['300']
                  }
                  color={
                    selectedCalendarView === 'timeGridWeek' &&
                    appColors.brandWhite[900]
                  }
                  cursor="pointer"
                  onClick={() => handleChange('timeGridWeek')}
                >
                  <Text fontWeight={600}>Week</Text>
                </Flex>
                <Flex
                  padding="8px 27px"
                  bg={
                    selectedCalendarView === 'dayGridMonth' &&
                    appColors.brandPrimary['300']
                  }
                  borderRadius="0px 30px 30px 0px"
                  color={
                    selectedCalendarView === 'dayGridMonth' &&
                    appColors.brandWhite[900]
                  }
                  cursor="pointer"
                  onClick={() => handleChange('dayGridMonth')}
                >
                  <Text fontWeight={600}>Month</Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex width="100%" alignItems="flex-start" gap="20px">
              <Flex
                width="100%"
                justifyContent={isMobile ? 'center' : 'space-between'}
                alignItems="center"
                gap="25px"
                flexWrap="wrap"
              >
                <Flex
                  alignItems="center"
                  gap="5px"
                  flexWrap="wrap"
                  display={isMobile ? 'none' : 'flex'}
                >
                  <AppSelectV3
                    width="160px"
                    options={BatchData?.data?.batch_names}
                    control={control}
                    placeholder="Batches"
                    {...register('batches')}
                    bg={appColors.brandWhite[900]}
                    isMulti
                    ishideOption
                  />
                  <AppSelectV3
                    width="160px"
                    options={BatchData?.data?.batch_status}
                    control={control}
                    placeholder="Batch Status"
                    {...register('batch_status')}
                    bg={appColors.brandWhite[900]}
                    isClearable
                  />
                  <AppSelectV3
                    width="200px"
                    options={BatchData?.data?.course_grade}
                    control={control}
                    placeholder="Courses & Grades"
                    {...register('course_and_grade')}
                    bg={appColors.brandWhite[900]}
                    isMulti
                    ishideOption
                  />
                  <Text
                    color={appColors.brandPrimary['300']}
                    cursor="pointer"
                    textDecoration="underline"
                    ml="10px"
                    onClick={handleReset}
                  >
                    Clear Filter
                  </Text>
                </Flex>
                <Flex alignItems="center" gap="15px">
                  <Flex alignItems="center" gap={2}>
                    <Box
                      width={isMobile ? '10px' : '14px'}
                      height={isMobile ? '10px' : '14px'}
                      bg="#206BC4"
                      borderRadius="50%"
                      border="1px solid #DADCDE"
                    />
                    <Text fontSize={isMobile ? '8px' : '12px'}>
                      Not yet Started
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap={2}>
                    <Box
                      width={isMobile ? '10px' : '14px'}
                      height={isMobile ? '10px' : '14px'}
                      bg="#8B5971"
                      borderRadius="50%"
                      border="1px solid #DADCDE"
                    />
                    <Text fontSize={isMobile ? '8px' : '12px'}>Ongoing</Text>
                  </Flex>
                  <Flex alignItems="center" gap={2}>
                    <Box
                      width={isMobile ? '10px' : '14px'}
                      height={isMobile ? '10px' : '14px'}
                      bg="#009432"
                      borderRadius="50%"
                      border="1px solid #DADCDE"
                    />
                    <Text fontSize={isMobile ? '8px' : '12px'}>
                      Slot Available
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap={2}>
                    <Box
                      width={isMobile ? '10px' : '14px'}
                      height={isMobile ? '10px' : '14px'}
                      bg="#DA3730"
                      borderRadius="50%"
                      border="1px solid #DADCDE"
                    />
                    <Text fontSize={isMobile ? '8px' : '12px'}>Completed</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {!calendarIsLoading && (
              <Box width="100%">
                <AppCalendar
                  state={state}
                  schedulerData={classes}
                  calendarRef={calendarRef}
                  filterBatchStatus={
                    watch('batch_status')?.value ?? selectedBatchStatus
                  }
                />
              </Box>
            )}
          </Flex>
        </Flex>
      </AppLoader>
    </TeacherDashboardLayout>
  );
}

export default TeacherAvailability;
