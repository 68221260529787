import {
  Box,
  Flex,
  useDisclosure,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
// import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
import ProgramTitleCard from 'version-two/components/ProgramTitleCard/ProgramTitleCard';
import AppText from 'version-two/components/Typography/AppText';
// import { CopyContentIcon, ManageLockIcon } from 'version-two/assets';
// import GroupUserProfileImage from 'version-two/components/GroupUserProfileImage/GroupUserProfileImage';
import TeacherProgramDetailDrawable from 'version-two/components/TeacherProgramDetailDrawable/TeacherProgramDetailDrawable';
import ManageModuleAccessBulk from 'version-two/components/ManageModuleAccessBulk/ManageModuleAccessBulk';
import { useQuery } from 'react-query';
import { GET_TEACHER_ID_SCHEDULE } from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
// import AppLoader from 'version-two/components/AppLoader/AppLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
// import { Select } from 'chakra-react-select';
// import useStudentSearch from 'version-two/hooks/useStudentSearch';
import {
  MAKE_TEACHER_PROGRAM_DETAIL,
  MAKE_APP_WEB_RECORDINGS_VIDEO_PLAYER,
} from 'version-two/navigation/routes';
import { PlayIcon } from 'version-two/assets';
import appColors from 'version-two/theme/appColors';

function TeacherSchedule() {
  const navigate = useNavigate();
  const { programSlug } = useParams();

  const { data: programDetail } = useQuery(
    GET_TEACHER_ID_SCHEDULE(programSlug),
    () => makeGetRequest(GET_TEACHER_ID_SCHEDULE(programSlug)),
  );

  const [activeModule, setActiveModule] = useState();

  const { isOpen: programDrawableIsOpen, onClose: programDrawableOnClose } =
    useDisclosure();

  const {
    isOpen: moduleManageIsOpen,
    onOpen: moduleManageOnOpen,
    onClose: moduleManageOnClose,
  } = useDisclosure();

  const handleManageClick = (module) => {
    setActiveModule(module);
    moduleManageOnOpen();
  };

  const handleManageClose = () => {
    moduleManageOnClose();
  };

  const navigateToVideoPlayer = (id) => {
    window.open(MAKE_APP_WEB_RECORDINGS_VIDEO_PLAYER(programSlug, id));
  };

  // const handleContentOpen = (module) => {
  //   setActiveModule(module);
  //   programDrawableOnOpen();
  // };

  const handleDrawableClose = () => {
    setActiveModule(null);
    programDrawableOnClose();
  };
  const handleContentClose = () => {
    navigate(MAKE_TEACHER_PROGRAM_DETAIL(programSlug));
    handleDrawableClose();
  };

  // const { searchDefaultProps } = useStudentSearch({ programSlug });

  const isMobile = useBreakpointValue({ base: true, sm: false });

  // useEffect(() => {
  //   if (programDetail && moduleSlug) {
  //     programDetail.data.schdules.some((programData) => {
  //       if (programData.id === moduleSlug) {
  //         handleContentOpen(programData);
  //         return true;
  //       }
  //       return false;
  //     });
  //   }

  //   if (!moduleSlug) {
  //     handleDrawableClose();
  //   }
  // }, [moduleSlug, programDetail]);

  // const pageBreadCrumbDetail = [
  //   {
  //     name: 'Your Batches',
  //     link: TEACHER_DASHBOARD,
  //   },
  //   {
  //     name: programDetail?.data?.schedules.identity,
  //     link: MAKE_TEACHER_PROGRAM_DETAIL(programSlug),
  //   },
  // ];

  const showValidDate = (date) => {
    let month = '';
    const dateArr = date.split('-');
    if (dateArr[1] === '01') {
      month = 'Jan,';
    } else if (dateArr[1] === '02') {
      month = 'Feb,';
    } else if (dateArr[1] === '03') {
      month = 'Mar,';
    } else if (dateArr[1] === '04') {
      month = 'Apr,';
    } else if (dateArr[1] === '05') {
      month = 'May,';
    } else if (dateArr[1] === '06') {
      month = 'Jun,';
    } else if (dateArr[1] === '07') {
      month = 'Jul,';
    } else if (dateArr[1] === '08') {
      month = 'Aug,';
    } else if (dateArr[1] === '09') {
      month = 'Sep,';
    } else if (dateArr[1] === '10') {
      month = 'Oct,';
    } else if (dateArr[1] === '11') {
      month = 'Nov,';
    } else {
      month = 'Dec,';
    }
    return `${dateArr[2]} ${month} ${dateArr[0]}`;
  };
  const giveValidDuration = (timing) => {
    const arr = timing.split(':');
    arr.pop();
    if (arr[0] === '0') return `00:${arr[1]}`;
    return arr.join(':');
  };

  // const falseCase=['substituted_assigned','upcoming',]
  const isRedTag = (status) => {
    let isTrue;
    if (status === 'substitute_assigned' || status === 'upcoming') {
      isTrue = true;
    } else {
      isTrue = false;
    }
    // console.log('red', isTrue, status);
    return isTrue;
  };

  const giveProperStatus = (stat) => {
    let statusName;
    if (stat === 'substitute_assigned') {
      statusName = 'Substitute assigned';
    } else {
      statusName = stat.charAt(0).toUpperCase() + stat.slice(1);
    }
    return statusName;
  };

  return (
    <>
      <ProgramTitleCard
        title="Schedule"
        type={programDetail?.data.batch.identity}
      />
      <Box>
        {/* <Stack spacing={4} mt={5}>
          <Select {...searchDefaultProps} />
        </Stack> */}
        <Flex
          alignItems="center"
          mt={3}
          py={5}
          boxShadow={`0px 1px 0px 0px ${appColors.brandGrey['100']} inset;`}
          display={{ base: 'none', sm: 'flex' }}
          width="100%"
        >
          <Box
            ml={3}
            style={{
              flexBasis: '20%',
            }}
          >
            <AppText title="WEEK" variant="tableHeader" />
          </Box>
          <Box
            style={{
              flexBasis: '20%',
            }}
          >
            <AppText title="STATUS" variant="tableHeader" />
          </Box>
          <Box
            style={{
              flexBasis: '20%',
            }}
          >
            <AppText title="DATE" variant="tableHeader" />
          </Box>
          <Box
            style={{
              flexBasis: '20%',
            }}
          >
            <AppText title="TRAINER'S NAME" variant="tableHeader" />
          </Box>
          <Box
            style={{
              flexBasis: '20%',
            }}
          >
            <AppText title="CLASS RECORDINGS" variant="tableHeader" />
          </Box>
        </Flex>
        {programDetail?.data.schedules.map((liveClassData) => (
          <AppFlex
            customStyles={{
              alignItems: 'center',
              fontWeight: '600',
              wordBreak: 'break-word',
            }}
            variant="moduleListingCard"
            key={liveClassData.id}
          >
            <Flex alignItems="center" width="100%">
              <Flex
                width="100%"
                flexDirection={{ base: 'column', sm: 'row' }}
                display={{ base: 'block', sm: 'contents' }}
                direction={{ base: 'column', sm: 'row' }}
              >
                <Box
                  style={{
                    flexBasis: '20%',
                    fontWeight: '600',
                  }}
                >
                  <AppText
                    customStyles={{
                      fontSize: { base: '11px', sm: '16px' },
                      color: isRedTag(liveClassData.status)
                        ? '#C7261E'
                        : 'black',
                    }}
                    title={`Week ${liveClassData.week_number}`}
                  />
                </Box>
                <Box
                  style={{
                    flexBasis: '20%',
                  }}
                >
                  <AppText
                    title={giveProperStatus(liveClassData.status)}
                    variant={liveClassData.status}
                    customStyles={{
                      fontWeight: '600',
                      borderRadius: '6px',
                      display: 'inline',
                      color: isRedTag(liveClassData.status)
                        ? '#C7261E'
                        : 'black',
                      border:
                        isRedTag(liveClassData.status) && !isMobile
                          ? '1px solid #C7261E'
                          : '',
                      padding: {
                        base: '0px',
                        sm: isRedTag(liveClassData.status) ? '3px 5px' : '0px',
                      },
                    }}
                  />
                </Box>
                <Box
                  style={{
                    flexBasis: '20%',
                    textTransform: 'capitalize',
                  }}
                >
                  <AppText
                    title={
                      isMobile
                        ? `${showValidDate(liveClassData.date)} - ${
                            liveClassData.substitute_teacher_name ||
                            liveClassData.teacher_name
                          }`
                        : showValidDate(liveClassData.date)
                    }
                    variant={liveClassData.status}
                    customStyles={{
                      fontSize: { base: '11px', sm: '16px' },
                      color: isRedTag(liveClassData.status)
                        ? '#C7261E'
                        : '#737373',
                    }}
                  />
                </Box>
                <Box
                  display={{ base: 'none', sm: 'block' }}
                  style={{
                    flexBasis: '20%',
                    textTransform: 'capitalize',
                  }}
                >
                  <AppText
                    // title={
                    //   liveClassData.status === 'substituted'
                    //     ? liveClassData.substitute_teacher_name
                    //     : liveClassData.teacher_name
                    // }
                    title={
                      liveClassData.substitute_teacher_name ||
                      liveClassData.teacher_name
                    }
                    variant={liveClassData.status}
                    customStyles={{
                      fontSize: { base: '11px', sm: '16px' },
                      color: isRedTag(liveClassData.status)
                        ? '#C7261E'
                        : '#737373',
                    }}
                  />
                </Box>
              </Flex>
              {liveClassData.file && (
                <Flex
                  flexBasis={{ base: '30%', sm: '20%' }}
                  style={{
                    gap: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigateToVideoPlayer(liveClassData.id)}
                >
                  <AppText
                    title={giveValidDuration(liveClassData.file_duration)}
                    variant={liveClassData.status}
                  />
                  <Image src={PlayIcon} alt="" />
                </Flex>
              )}
            </Flex>
            {/* {programDetail?.data?.teacher
              ?.can_provide_live_class_module_access && (
              <Box
                style={{
                  flexBasis: '20%',
                }}
                onClick={() => handleManageClick(liveClassData)}
              >
                <Flex
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <img src={ManageLockIcon} alt="" />
                  <AppText title="Manage" size="xs" variant="manageLabel" />
                </Flex>
              </Box>
            )} */}
          </AppFlex>
        ))}
      </Box>
      {programDrawableIsOpen && (
        <TeacherProgramDetailDrawable
          isOpen={programDrawableIsOpen}
          onClose={handleContentClose}
          activeModule={activeModule.id}
          activeModuleName={activeModule.identity}
          activeStudentManage={handleManageClick}
          canManageAccess={
            programDetail?.data?.teacher?.can_provide_live_class_module_access
          }
        />
      )}
      {moduleManageIsOpen && (
        <ManageModuleAccessBulk
          isOpen={moduleManageIsOpen}
          onClose={handleManageClose}
          activeModule={activeModule.id}
          moduleName={activeModule.identity}
        />
      )}
    </>
  );
}

export default TeacherSchedule;
