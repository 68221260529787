// import { pdfjs, Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
// import { useEffect, useMemo, useState } from 'react';
// import AppBox from 'voxguru-v1/components/chakraLayoutOverrides/AppBox';
// import appColors from 'voxguru-v1/theme/appColors';
// import {  useBreakpointValue } from '@chakra-ui/react';
// import AppLoader from 'voxguru-v1/components/AppLoader/AppLoader';
// import useGetFileData from 'voxguru-v1/hooks/useGetFileData';
// import { useNavigate } from 'react-router-dom';
// import getIsWebView from 'voxguru-v1/utils/getIsWebView';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
// import { PdfWrapperBlock } from 'voxguru-v1/pages/common/AppPdfViewer/AppPdfViewer.styles';
// import getIsIOSDevice from 'voxguru-v1/utils/getIsIOSDevice';
// import AppFlex from 'voxguru-v1/components/chakraLayoutOverrides/AppFlex';
// import { BsZoomIn, BsZoomOut } from 'react-icons/bs';
// import { Icon } from '@chakra-ui/icons';

function AppPdfViewer() {
  // const [pdfBlob, setPdfBlob] = useState(null);
  // const [pdfBlobLoading, setPdfBlobLoading] = useState(true);
  // const [pdfRenderLoading, setPdfRenderLoading] = useState(true);
  // const [totalPages, setTotalPages] = useState(null);
  // const [pageScale, setPageScale] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);

  // eslint-disable-next-line max-len
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  // const isMobile = useBreakpointValue({ base: true, sm: false });

  // const navigate = useNavigate();
  // const isWebView = useMemo(() => getIsWebView(), []);

  // const { data, loading } = useGetFileData();

  // function onDocumentLoadSuccess({ numPages }) {
  //   setTotalPages(numPages);
  // }

  // const canGoToNext = () => currentPage < totalPages;
  //
  // const canGoToPrev = () => currentPage > 1;

  // const handlePrevPage = () => {
  //   if (canGoToPrev()) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };
  // const handleNextPage = () => {
  //   if (canGoToNext()) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // useEffect(() => {
  //   setPdfRenderLoading(true);
  // }, [currentPage]);

  // useEffect(() => {
  //   if (!loading && data) {
  //     fetch(data)
  //       .then((res) => res.blob())
  //       .then((file) => {
  //         setPdfBlob(file);
  //         setPdfBlobLoading(false);
  //       });
  //   }
  // }, [data, loading]);

  // const pdfLoading = loading || pdfBlobLoading;

  // const renderType = getIsIOSDevice() ? 'svg' : 'canvas';

  // const handlePageZoomIn = () => {
  //   setPageScale((prevState) => prevState + 0.1);
  // };

  // const handlePageZoomOut = () => {
  //   setPageScale((prevState) => prevState - 0.1);
  // };

  return (
    <div>V1</div>
    // <AppLoader isLoading={pdfLoading}>
    //   {!pdfLoading && (
    //     <AppBox
    //       customStyles={{
    //         height: '100vh',
    //         width: '100%',
    //         overflow: 'scroll',
    //         backgroundColor: appColors.brandGrey['500'],
    //       }}
    //     >
    //       {isWebView && (
    //         <CloseButton
    //           onClick={() => navigate(-1)}
    //           size="md"
    //           style={{
    //             position: 'absolute',
    //             top: '10px',
    //             left: '10px',
    //           }}
    //           zIndex="100"
    //         />
    //       )}
    //       <AppFlex
    //         customStyles={{
    //           justifyContent: 'center',
    //           position: 'fixed',
    //           right: 0,
    //           left: 0,

    //           paddingY: '10px',

    //           background: 'rgba(0,0,0, 0.3)',
    //           zIndex: '99',
    //         }}
    //       >
    //         <Icon
    //           as={BsZoomIn}
    //           mx={3}
    //           boxSize={6}
    //           cursor="pointer"
    //           onClick={handlePageZoomIn}
    //         />
    //         <Icon
    //           as={BsZoomOut}
    //           mx={3}
    //           boxSize={6}
    //           cursor="pointer"
    //           onClick={handlePageZoomOut}
    //         />
    //       </AppFlex>
    //       <PdfWrapperBlock>
    //         <AppLoader
    //           height="100px"
    //           isLoading={pdfRenderLoading}
    //           customStyles={{
    //             position: 'absolute',
    //             zIndex: 100,
    //             right: '47%',
    //             top: '44%',
    //           }}
    //         />

    //         {pdfBlob && (
    //           <Document
    //             file={pdfBlob}
    //             /* eslint-disable-next-line react/jsx-no-bind */
    //             onLoadSuccess={onDocumentLoadSuccess}
    //             renderMode={renderType}
    //             loading={<AppLoader />}
    //           >
    //             {Array.from(new Array(totalPages), (el, index) => (
    //               <AppBox customStyles={{ my: 5 }}>
    //                 <Page
    //                   // object-fit="fill"
    //                   key={`page_${index + 1}`}
    //                   pageNumber={index + 1}
    //                   width={isMobile && 350}
    //                   loading={<AppLoader />}
    //                   onRenderSuccess={() => {
    //                     setPdfRenderLoading(false);
    //                   }}
    //                   style={{
    //                     marginTop: '200px',
    //                   }}
    //                   scale={pageScale}
    //                   renderTextLayer={false}
    //                 />
    //               </AppBox>
    //             ))}

    //             {/* <IconWrapper isMobile={isMobile}> */}
    //             {/*  <IconButton */}
    //             {/*    disabled={!canGoToPrev()} */}
    //             {/*    colorScheme="blue" */}
    //             {/*    aria-label="go-previous" */}
    //             {/*    icon={<ChevronLeftIcon fontSize={fontSizes.md} />} */}
    //             {/*    backgroundColor={appColors.brandGrey['50']} */}
    //             {/*    color={appColors.brandGrey['900']} */}
    //             {/*    mr={2} */}
    //             {/*    onClick={handlePrevPage} */}
    //             {/*  /> */}
    //             {/*  <AppText title={`${currentPage} of ${totalPages}`} /> */}
    //             {/*  <IconButton */}
    //             {/*    disabled={!canGoToNext()} */}
    //             {/*    colorScheme="blue" */}
    //             {/*    aria-label="go-next" */}
    //             {/*    icon={<ChevronRightIcon fontSize={fontSizes.md} />} */}
    //             {/*    backgroundColor={appColors.brandGrey['50']} */}
    //             {/*    color={appColors.brandGrey['900']} */}
    //             {/*    ml={2} */}
    //             {/*    onClick={handleNextPage} */}
    //             {/*  /> */}
    //             {/* </IconWrapper> */}
    //           </Document>
    //         )}
    //       </PdfWrapperBlock>
    //     </AppBox>
    //   )}
    // </AppLoader>
  );
}

export default AppPdfViewer;
