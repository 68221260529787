const appColors = {
  brandWhite: {
    900: '#FFFFFF',
  },

  brandGrey: {
    50: '#F2F2F2',
    100: '#D9D9D9',
    150: '#D9D9D9CC',
    200: '#BFBFBF',
    300: '#A6A6A6',
    400: '#8C8C8C',
    500: '#737373',
    600: '#656D7729',
    700: '#404040',
    900: '#0D0D0D',
  },

  brandPrimary: {
    300: '#E87663',
    400: '#E14B38',
  },

  brandSecondary: {
    100: '#E2CFDB',
    200: '#CDB1C1',
    500: '#8B5971',
  },

  brandRed: {
    100: '#C7261E',
  },
};

export default appColors;
