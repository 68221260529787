import { getVoxguruVersion } from 'appConfig';
import { useEffect } from 'react';
import AppSwitch from './version-two/navigation/switch/index';
import AppSwitchV1 from './voxguru-v1/navigation/switch/index';

// import AppSwitch as AppSecond from './voxguru-v2/navigation/switch/index';
// import {getHostAPIUrl} from 'appConfig';

function App() {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Assuming the service worker has already been registered elsewhere
    if (navigator.serviceWorker) {
      // Listen for messages from the service worker
      const handleServiceWorkerMessage = () => {
        // console.log(
        //   'Received a message from service worker:',
        //   event,
        //   event.data.message,
        // );
      };

      navigator.serviceWorker.addEventListener(
        'message',
        handleServiceWorkerMessage,
      );

      // Cleanup listener when the component unmounts
      return () => {
        navigator.serviceWorker.removeEventListener(
          'message',
          handleServiceWorkerMessage,
        );
      };
    }
  }, []);

  return getVoxguruVersion() ? <AppSwitchV1 /> : <AppSwitch />;
}

export default App;
