/* eslint-disable no-nested-ternary */
import {
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { EDIT_TRAINER_COURSELIST } from 'version-two/api/urls';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import appColors from 'version-two/theme/appColors';
import { toastFunction } from 'version-two/components/CommonFunctions/CommonFunctions';

function CourseAndGrade({ isEdit, classListing, courses, setCourses }) {
  const toast = useToast();
  const [removeGrade, setRemoveGrade] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState({});
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const GradeOptions = [
    {
      label: 'Grade 1',
      value: 'Grade 1',
    },
    {
      label: 'Grade 2',
      value: 'Grade 2',
    },
    {
      label: 'Grade 3',
      value: 'Grade 3',
    },
    {
      label: 'Grade 4',
      value: 'Grade 4',
    },
    {
      label: 'Grade 5',
      value: 'Grade 5',
    },
    {
      label: 'Grade 6',
      value: 'Grade 6',
    },
    {
      label: 'Grade 7',
      value: 'Grade 7',
    },
    {
      label: 'Grade 8',
      value: 'Grade 8',
    },
    {
      label: 'Grade 9',
      value: 'Grade 9',
    },
    {
      label: 'Grade 10',
      value: 'Grade 10',
    },
    {
      label: 'Grade 11',
      value: 'Grade 11',
    },
    {
      label: 'Grade 12',
      value: 'Grade 12',
    },
    {
      label: 'Grade 13',
      value: 'Grade 13',
    },
    {
      label: 'Grade 14',
      value: 'Grade 14',
    },
    {
      label: 'Grade 15',
      value: 'Grade 15',
    },
    {
      label: 'Grade 16',
      value: 'Grade 16',
    },
    {
      label: 'Grade 17',
      value: 'Grade 17',
    },
    {
      label: 'Grade 18',
      value: 'Grade 18',
    },
  ];

  const { mutate } = useMutation(
    (body) =>
      makePostRequest(
        EDIT_TRAINER_COURSELIST('ec87e6e0-8d18-4d2e-9ac1-f53fc85f4171'),
        body,
      ),
    {
      onSuccess: () => {
        setRemoveGrade(true);
      },
      onError: () => {
        setSelectedGrade(null);
        setRemoveGrade(false);
        toastFunction(
          toast,
          'The course cannot be removed because it is currently ongoing',
          'error',
        );
      },
    },
  );

  useEffect(() => {
    const initialCourses = classListing.reduce((acc, item) => {
      if (!acc[item.class]) {
        acc[item.class] = [];
      }
      acc[item.class].push(item.grade);
      return acc;
    }, {});
    setCourses(initialCourses);
  }, [classListing]);

  useEffect(() => {
    if (removeGrade) {
      setCourses((prevCourses) => {
        const updatedCourses = { ...prevCourses };
        const { course, grade } = selectedGrade;
        if (updatedCourses[course]) {
          updatedCourses[course] = updatedCourses[course].filter(
            (g) => g !== grade,
          );
          if (updatedCourses[course].length === 0) {
            delete updatedCourses[course];
          }
        }
        return updatedCourses;
      });
      setSelectedGrade(null);
      setRemoveGrade(false);
    }
  }, [removeGrade]);

  const handleCheckboxChange = (courseName, gradeValue, isChecked) => {
    if (!isChecked) {
      mutate({
        course: `${courseName}-${gradeValue}`,
      });
      setSelectedGrade({ course: courseName, grade: gradeValue });
    } else {
      setCourses((prevGrades) => ({
        ...prevGrades,
        [courseName]: [...(prevGrades[courseName] || []), gradeValue],
      }));
    }
  };

  const renderGrades = (courseName) => {
    const courseGrades = courses[courseName] || [];
    const gradesToShow = isEdit
      ? GradeOptions
      : GradeOptions.filter((grade) => courseGrades.includes(grade.value));

    return (
      <Flex
        gap={isMobile ? '20px' : '40px 20px'}
        flexWrap="wrap"
        pl={isMobile ? '20px' : '0'}
      >
        {gradesToShow.map((grade) => {
          const isSelected = courseGrades.includes(grade.value);
          return (
            <Flex
              key={grade.value}
              width={!isEdit ? 'auto' : isMobile ? '80px' : '92px'}
              gap={2}
            >
              {isEdit && (
                <Checkbox
                  isChecked={isSelected}
                  onChange={(e) =>
                    handleCheckboxChange(
                      courseName,
                      grade.value,
                      e.target.checked,
                    )
                  }
                />
              )}
              <Text
                fontSize={isMobile ? '13px' : '16px'}
                color={appColors.brandGrey[500]}
              >
                {grade.value}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  return (
    <Flex flexDirection="column" gap={5}>
      <Text fontSize="16px" fontWeight="600">
        Course & Grade
      </Text>
      <Flex
        width="100%"
        flexDirection="column"
        gap={5}
        borderTop={isMobile ? 'none' : `1px solid ${appColors.brandGrey[200]}`}
      >
        {/* MOBILE VIEW */}
        <Flex
          flexDirection="column"
          gap={2}
          display={isMobile ? 'flex' : 'none'}
        >
          <Text fontSize="16px" fontWeight="600">
            Carnatic
          </Text>
          {renderGrades('Carnatic')}
        </Flex>
        <Flex
          flexDirection="column"
          gap={2}
          display={isMobile ? 'flex' : 'none'}
        >
          <Text fontSize="16px" fontWeight="600">
            Voice Culture
          </Text>
          {renderGrades('Voice Culture')}
        </Flex>

        {/* DESKTOP VIEW */}
        <TableContainer display={isMobile ? 'none' : 'block'}>
          <Table colorScheme="facebook">
            <Thead>
              <Tr>
                <Th py="20px">
                  <Text
                    color={appColors.brandGrey[900]}
                    fontSize="12px"
                    fontWeight={700}
                    pl="30px"
                  >
                    COURSES
                  </Text>
                </Th>
                <Th>
                  <Text
                    color={appColors.brandGrey[900]}
                    fontSize="12px"
                    fontWeight={700}
                  >
                    GRADES
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr width="100%">
                <Td width="30%">
                  <Text pl="30px">Carnatic</Text>
                </Td>
                <Td width="70%">{renderGrades('Carnatic')}</Td>
              </Tr>
              <Tr width="100%">
                <Td width="30%">
                  <Text pl="30px">Voice Culture</Text>
                </Td>
                <Td width="70%">{renderGrades('Voice Culture')}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Flex>
  );
}

export default CourseAndGrade;
