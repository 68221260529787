/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import AppTeacherNavbar from 'version-two/components/AppTeacherNavbar/AppTeacherNavbar';
import { Box, Container, useBreakpointValue } from '@chakra-ui/react';
import PageBreadCrumb from 'version-two/components/PageBreadCrumb/PageBreadCrumb';
import { GET_TRAINER_ID } from 'version-two/api/urls';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import { useQuery } from 'react-query';
import useAppStore from 'version-two/store';
import { useEffect } from 'react';
import { requestNotificationPermission } from 'version-two/components/CCConvoWithMessageWrapper/helpers';

function TeacherDashboardLayout({
  children,
  isProgram,
  breadCrumb,
  tabIndex,
  maxWidth = true,
  setClassDetails,
}) {
  const { setTrainerId } = useAppStore();
  const userId = useAppStore.getState().user_id;

  useEffect(() => {
    requestNotificationPermission();
  }, []);
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const { data, refetch } = useQuery(
    GET_TRAINER_ID(userId),
    () => makeGetRequest(GET_TRAINER_ID(userId)),
    {
      onSuccess: async (res) => {
        await setTrainerId(res?.data);
      },
      enabled: Boolean(userId),
    },
  );

  function reloadPage() {
    const reload = localStorage.getItem('reload');
    if (reload === undefined || reload === null) {
      localStorage.setItem('reload', true);
      window.location.reload();
    }
  }

  useEffect(() => {
    reloadPage();
    refetch();
  }, []);

  return (
    <Box>
      <AppTeacherNavbar
        isProgram={isProgram}
        tabIndex={tabIndex}
        breadCrumb={breadCrumb}
        setClassDetails={setClassDetails}
      />
      {!isMobile && <PageBreadCrumb breadCrumb={breadCrumb} />}
      <Container maxW={maxWidth ? '1000px' : 'auto'}>{children}</Container>
    </Box>
  );
}

export default TeacherDashboardLayout;
